import React, { useState, useEffect } from 'react'
import { Box, Button, Card, Text, Spinner } from "@chakra-ui/react"
import NavBar from '../../Components/Navbar/NavBar'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import CardsStore from './CardsStore'


const BrandsManagement = ({ currentUserId }) => {
    const [brandsStore, setBrandsStore] = useState([
        {
            brandName: "KR",
            brandId: 1,
            color: "orange",
            Stores: []
        },
        {
            brandName: "SBC",
            brandId: 2,
            color: "red",
            Stores: []
        },
        {
            brandName: "PB",
            brandId: 3,
            color: "blue",
            Stores: []
        },
        {
            brandName: "JB",
            brandId: 4,
            color: "green",
            Stores: []
        },
    ])
    const [isLoading, setIsLoading] = useState(false)
    const [trigger, setTrigger] = useState(false)
    useEffect(() => {
        const fetchStores = async () => {
            setIsLoading(true);
            try {
                // Fetch stores for each brand
                const updatedBrands = await Promise.all(
                    brandsStore.map(async (brand) => {
                        const response = await axios.post(
                            `${process.env.REACT_APP_BACKEND_API_URL}/maintenance/getBrands`,
                            { brandId: brand.brandId }
                        );
                        // Return the brand with update d Stores
                        return {
                            ...brand,
                            Stores: response.data,
                        };
                    })
                );

                // Update the state with the fetched data
                setBrandsStore(updatedBrands);
            } catch (error) {
                console.error("Error fetching stores:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchStores();
    }, [trigger]);
    return (
        <>
            <NavBar inManagement />
            <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                <Text
                    fontSize={{ base: "2xl", lg: "3xl" }}
                    fontWeight={"bold"}
                    textAlign={"left"}
                    mt={4}
                    ml={6}
                >
                    Brand Management
                </Text>
                <Box display={"flex"} flexDirection={"column"}>
                    {brandsStore.map((store) => (
                        <CardsStore key={store.brandId} data={store} isLoading={isLoading} trigger={() => setTrigger(prev => !prev)} />
                    ))}
                </Box>
            </Box>

        </>
    )
}

export default BrandsManagement