import React, { useState } from 'react';
// import SBC_NavBar from '../../Access_NavBar/SBC_NavBar';
import { Flex, Box } from '@chakra-ui/react';
import sbc_cover from '../../../Assets/sbc_cover.png';
// import KR_NavBar from '../../../Access_NavBar/KR_NavBar';
import sbc_logo from '../../../Assets/sbc_logo.png';
import NavBar from '../../../Components/Navbar/NavBar';
import Sales from "./SalesSBC"
import Reports from "./ReportsSBC"
import About from './AboutSBC';
import img from "../../../Assets/boxes.jpg"

function SBC() {
    const [selectedOption, setSelectedOption] = useState('Sales');

    const handleTabClick = (tab) => {
        setSelectedOption(tab);
    };
    return (
        <Box>
            {/* <KR_NavBar onTabClick={handleTabClick} logo={sbc_logo} tabIndicatorColor="red.500" /> */}
            <NavBar logo={sbc_logo} tabIndicatorColor="red.500" onTabClick={handleTabClick} />
            <Flex justifyContent="center" position="relative">
                {/* Place the image with a lower zIndex */}
                {/* <Box
                    mb={40}
                    style={{
                        backgroundImage: `url(${sbc_cover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: "100%",
                        height: '250px'
                    }}
                /> */}
            </Flex>
            <Box display={"flex"} alignItems={"center"} bgImage={`url(${img})`} bgRepeat={"no-repeat"} bgSize={"cover"} justifyContent={"center"} w={"100%"}>
                <Box w={"90%"}>
                    {/* Render content based on the selected option */}
                    {selectedOption === 'Sales' && <Sales content={"Sales SBC"} color={"red"} />}
                    {selectedOption === 'Reports' && <Reports content={"Reports SBC"} />}
                </Box>
            </Box>
        </Box>
    );
}

export default SBC;
