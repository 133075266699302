import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table'
import HelveticaBold from '../Files/fonts/Helvetica-Bold.ttf';
Font.register({
    family: 'Times new Roman',
    src: HelveticaBold
})

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        width: "100%",
        padding: 5,
        lineHeight: 0.9
    },
    section: {
        width: "100%",
        margin: 5,
        padding: 10,
    },
    pageTitle: {
        fontWeight: "bold",
        fontSize: 13,
        textAlign: 'center',
    },
    pageSubTitle: {
        fontWeight: "normal",
        fontSize: 13,
        textAlign: 'center',
    },
    details: {
        fontWeight: "bold",
        fontSize: 10,
    },
    detailsLeft: {
        fontWeight: "bold",
        fontSize: 9,
        textAlign: "left",
        marginBottom: "2px",
        marginRight: "5px"
    },
    divider: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 5
    },
    grossDetails: {
        fontWeight: "bold",
        fontSize: 11,
        marginBottom: 10,
        marginTop: 5,
        textAlign: "right",
    },
    lineSeperator: {
        flex: 1,
        height: 1,
        backgroundColor: 'black',
        marginRight: 10,
        opacity: 0.5,
    },
    bigLineSeperator: {
        marginTop: 5,
        flex: 1,
        height: 4,
        backgroundColor: 'black',
        marginRight: 10,
        opacity: 0.5,
    },
    insideDetails: {
        marginLeft: 20
    },
    insideText: {
        fontSize: 9,
        marginBottom: 5,
        lineHeight: 0.77

    },
    redText: {
        color: "red"
    },
    pushBottomViewGrossSales: {
        marginTop: 18
    },
    bigTitle: {
        fontWeight: "extrabold",
        fontSize: 14,
        marginTop: 5,
    },
    ASalesDscountVat: {
        marginLeft: 35
    },
    ASettlement: {
        marginLeft: 12
    },
    ANONCash: {
        marginLeft: 22
    },
    AtitleLined: {
        fontWeight: "bold",
        marginTop: 17.5,
        marginBottom: 10,
        textDecoration: "underline"
    },
    priceASales: {
        fontSize: 10
    },
    SalesNetofDiscount: {
        fontSize: 10,
        marginLeft: 35
    },
    DineInTakeOut: {
        width: "80%",
        marginLeft: 20
    },
    A2Settlement: {
        fontSize: 11,
        textDecoration: "underline"
    },
    settlementTitle: {
        fontSize: 11,
        marginTop: 10
    },
    settlementInsideText: {
        fontSize: 9,
        lineHeight: 0.77
    },
    settlemenDivider: {
        marginLeft: 20
    },
    secondPage: {
        width: "80%"
    },
    noneCash: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",

    },
    noneCashDivider: {
        marginLeft: 40,
        width: "60%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",

    },
    noneCashAmount: {
        marginLeft: 100,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between"
    },
    B1: {
        marginLeft: 40
    },
    BNonRevenue: {
        width: "40%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boldText: {
        fontFamily: "Helvetica-Bold"
    },
    gridContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
    },
    gridItem: {
        flexGrow: 1,
        flexBasis: '16%', // for 6 items per row
        textAlign: 'right',
        fontSize: 9
    },
    table: {
        display: "table",
        width: "70%",
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableCol: {
        width: "20%",
    },
    tableCellLeft: {
        textAlign: 'left',
        margin: "auto",
        marginTop: 5,
        fontSize: 9
    },
    tableCellRight: {
        textAlign: 'right',
        margin: "auto",
        marginTop: 5,
        fontSize: 9
    },
    regularFont: {
        fontSize: 9
    },
    tableTitle: {
        fontSize: 9,
        paddingRight: 20
    }

});


const AlohaDFComponent = ({ data }) => {
    if (!data || data.length === 0) {
        return (
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text>No Data found.</Text>
                    </View>
                </Page>
            </Document>
        );
    }
    const totalGrossToday = (parseFloat(data.salesData.sales.VAT) + parseFloat(data.salesData.sales.VATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.pageTitle}>Daily Sales Report</Text>
                        {/* This is the first part */}
                        <View style={styles.divider}>
                            <View>
                                <Text style={styles.details}>Date Generated: {data.currDate}</Text>
                                <Text style={styles.details}>Store Code:        {data.storeCode}</Text>
                                <Text style={styles.details}>Store Name:       {data.PosId}</Text>
                                <Text style={styles.details}>Business Date :  {data.BizDate}</Text>
                            </View>
                            {/*<View>
                                <Text style={styles.detailsLeft}>{data.currDate}</Text>
                                <Text style={styles.detailsLeft}>{data.storeCode}</Text>
                                <Text style={styles.detailsLeft}>{data.PosId}</Text>
                                <Text style={styles.detailsLeft}>{data.BizDate}</Text>
                            </View>*/}
                        </View>
                        {/* end of firstPart */}
                        <View style={{ width: "93%" }}>
                            <Table tdStyle={{ padding: "6px", borderWidth: 0 }}>
                                {/*<TH>
                                    <TD style={{ fontSize: 11 }}>Gross Sale Opening Reading</TD>
                                    <TD style={{ fontSize: 11, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.openingData).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={{ fontSize: 11 }}>Gross Sales Closing Reading</TD>
                                    <TD style={{ fontSize: 11, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.closingData).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>*/}
                                <TH>
                                    <TD style={{ fontSize: 11 }}>Gross Sales Today</TD>
                                    <TD style={{ fontSize: 11, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalGrossAmt).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                            </Table>
                        </View>
                        {/* end of secondpart */}
                        {/* line seperator */}
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={styles.lineSeperator} />
                        </View>
                        {/* start of thirdapart */}
                        {/* end of thirdapart */}
                        {/* line seperator */}
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={styles.lineSeperator} />
                        </View>
                        {/* start of fourthPart */}
                        {/* tdStyle={{ borderWidth: 0 }} */}
                        <View style={{ width: "93%", marginTop: 2 }}>
                            <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Sales Summary</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>TC</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>VAT Exempt</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>TC</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>Vatable</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>Total</TD>
                                </TH>
                                

                                {/* Gross Sales Summary */}
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Gross Sales (Vat Ex)</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseInt(data.salesData.qty.VATE)}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalGrossBreakDownVATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseInt(data.salesData.qty.VAT)}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalGrossBreakDownVAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.totalGrossBreakDownVAT) + parseFloat(data.totalGrossBreakDownVATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    {/* Discoutn Summary */}
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Discount</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.dataDiscountVATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.dataDiscountVAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.dataDiscountVATE) + parseFloat(data.dataDiscountVAT)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    {/* Net Sales summary */}
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Net Sales</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.netsales.VATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.netsales.VAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.salesData.netsales.VAT) + parseFloat(data.salesData.netsales.VATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    {/* Vat Summary */}
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Vat</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>0.00</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalvat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalvat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    {/* Total summary */}
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Total</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.sales.VATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.sales.VAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.salesData.sales.VATE) + parseFloat(data.salesData.sales.VAT)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Grand Total</TD>

                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{totalGrossToday}</TD>
                                </TH>
                                {/* line seperator */}
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={styles.lineSeperator} />
                                </View>
                                
                               {/* <Text>{"\n"}</Text>/*}
                                {/* Settlement */}
                                <View style={{ width: "93%", marginTop: 10 }}>
                                    <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                        <TH>
                                            <TD style={[styles.insideText, styles.boldText]}>Settlement</TD>
                                            <TD></TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end", ...styles.boldText }}>{(parseFloat(data.totalCashierAmount) + parseFloat(data.totalPaymentNonCash)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                            <TD></TD>
                                        </TH>
                                    </Table>

                                    
                                </View>
                                <View style={{ width: "93%", marginTop: 10 }}>
                                    <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                        <TH>
                                            <TD style={[styles.settlementInsideText, styles.boldText]}>Cash</TD>
                                            <TD></TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end", ...styles.boldText }}>{parseFloat(data.totalCashierAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                            <TD></TD>
                                        </TH>
                                        <TH>
                                            <TD style={[styles.settlementInsideText]}>Cashier ID</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>Quantity</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>Amount</TD>
                                            <TD></TD>
                                        </TH>
                                        {data.cashierBreakDownReturned.map((item, index) => (
                                            <TR>
                                                <TD style={[styles.settlementInsideText]}>{item.CashierID}</TD>
                                                <TD></TD>
                                                <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(item.Amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                                <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}></TD>

                                            </TR>
                                        ))}
                                    </Table>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={styles.lineSeperator} />
                                </View>
                                {/* non Cash */}
                                <View style={{ width: "93%" }}>
                                    <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                        <TH>
                                            <TD style={[styles.settlementInsideText, styles.boldText]}>Non Cash</TD>
                                            <TD style={{ ...styles.settlementInsideText, ...styles.boldText, justifyContent: "flex-end" }}>{data.totalNonSalesCounter}</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end", ...styles.boldText }}>{data.totalNonSales.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                            <TD></TD>
                                        </TH>
                                        <TH>
                                            <TD style={[styles.settlementInsideText]}>Name</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>Quantity</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>POS</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>Amount - Settlement Report</TD>
                                        </TH>
                                        {data.NonSalesWithTenderDesc.map((paymentMethod, index) => (
                                            paymentMethod.TenderDesc !== "Cash" && (
                                                <TR key={index}>
                                                    <TD style={[styles.settlementInsideText]}>{paymentMethod.TenderDesc}</TD>
                                                    <TD style={[styles.settlementInsideText, { justifyContent: "flex-end" }]}>{paymentMethod.Quantity}</TD>
                                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(paymentMethod.Amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>_________________________</TD>
                                                </TR>
                                            )
                                        ))}
                                        <TH style={{ marginTop: 10 }}>
                                            <TD style={{ ...styles.settlementInsideText, ...styles.boldText }}> Settlement Total </TD>
                                            <TD></TD>
                                            <TD style={{ ...styles.settlementInsideText, ...styles.boldText, justifyContent: "flex-end" }}>{(parseFloat(data.totalPaymentNonCash) + parseFloat(data.totalCashierAmount)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                            <TD></TD>
                                        </TH>
                                    </Table>
                                </View>
                                {/* seperator */}
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={styles.bigLineSeperator} />
                                </View>
                                {/* Page Break */}
                            </Table>
                        </View>
                        {/* end of fourtPart */}
                        
                        {/* start of fifthPart */}
                        <Text style={styles.pageSubTitle}>******</Text>                   
                    </View>
                    
                </Page>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                    <Text style={styles.pageSubTitle}>Details</Text> 
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={styles.lineSeperator} />
                        </View>
                        <View style={{ width: "93%", marginTop: 2 }}>
                            <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>TC</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>VAT Exempt</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>TC</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>Vatable</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>Total</TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Gross Sales (Vat Ex)</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseInt(data.salesData.qty.VATE)}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalGrossBreakDownVATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseInt(data.salesData.qty.VAT)}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalGrossBreakDownVAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.totalGrossBreakDownVAT) + parseFloat(data.totalGrossBreakDownVATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                {data.dataGrossBreakDownReturned.map((item, index) => (
                                    <TH key={index}>
                                        <TD style={{ ...styles.insideText, justifyContent: "center" }}>{item.OrderTypeDesc}</TD>
                                        <TD key={index} style={{ ...styles.insideText, width: '100px', justifyContent: 'flex-end' }}>{item.QtyVATE}</TD>
                                        <TD key={index} style={{ ...styles.insideText, width: '100px', justifyContent: 'flex-end' }}>{parseFloat(item.VATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                        <TD key={index} style={{ ...styles.insideText, width: '100px', justifyContent: 'flex-end' }}>{item.QtyVAT}</TD>
                                        <TD key={index} style={{ ...styles.insideText, width: '100px', justifyContent: 'flex-end' }}>{parseFloat(item.VAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                        <TD key={index} style={{ ...styles.insideText, width: '100px', justifyContent: 'flex-end' }}>{parseFloat(parseFloat(item.VAT) + parseFloat(item.VATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    </TH>
                                ))}
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Discount</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.dataDiscountVATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.dataDiscountVAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.dataDiscountVATE) + parseFloat(data.dataDiscountVAT)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>

                                {data.dataDiscount
                                    .map((item, index) => (
                                        <TR>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: "center" }}>{item.discDesc}</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end' }}>{item.VATEQty}</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end' }}>{parseFloat(item.VATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end' }}>{item.VATQty}</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end' }}>{parseFloat(item.VAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                            <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end' }}>{parseFloat(parseFloat(item.VAT) + parseFloat(item.VATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                        </TR>
                                    ))}
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Net Sales</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.netsales.VATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.netsales.VAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.salesData.netsales.VAT) + parseFloat(data.salesData.netsales.VATE)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Vat</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>0.00</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalvat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.totalvat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Total</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.sales.VATE).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(data.salesData.sales.VAT).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{parseFloat(parseFloat(data.salesData.sales.VATE) + parseFloat(data.salesData.sales.VAT)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Grand Total</TD>
                                    <TD></TD>
                                    <TD></TD>
                                    <TD></TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: 'flex-end', ...styles.boldText }}>{totalGrossToday}</TD>
                                </TH>
                            </Table>
                        </View>
                        <View style={{ width: "93%", paddingLeft: 10, paddingRight: 10 }}>
                        
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={styles.bigLineSeperator} />
                        </View>
                        <View style={{ width: "93%", marginTop: 10 }}>
                            <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>Non Sales</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{data.nonSalesData.totalNonSalesCount}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end", ...styles.boldText }}>{data.nonSalesData.totalNonSales}</TD>
                                </TH>
                                {data.nonSalesData.nonSalesArray.map((item, index) => (
                                    <TR>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "center" }}>{item.tenderDesc}</TD>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{item.qty}</TD>
                                        <TD></TD>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{item.netAmount}</TD>
                                    </TR>
                                ))}
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>Voids</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseInt(data.NonSalesDataReturned.voidsCount)}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end", ...styles.boldText }}>{parseFloat(data.NonSalesDataReturned.voids).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH style={{ marginTop: 10 }}>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Cash - Over/Short</TD>
                                    <TD style={{ ...styles.settlementInsideText, ...styles.boldText, justifyContent: 'flex-end' }}>{parseFloat(data.totalShortOver).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD></TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText]}>Cashier ID</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>Short/Over</TD>
                                    <TD></TD>
                                    <TD style={[styles.settlementInsideText]}>
                                        <Text style={{ marginRight: 20 }}>Reason</Text>
                                    </TD>
                                </TH>
                                {data.shortOverDataReturned.map((item, index) => (
                                    <TR>
                                        <TD style={{ ...styles.settlementInsideText }}>{item.CashierID}</TD>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(item.Amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                        <TD></TD>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>_________________________</TD>
                                    </TR>
                                ))}

                                {/* <TH style={{ marginTop: 10 }}>
                                    <TD style={[styles.settlementInsideText, styles.boldText]}>Non Cash - Over/Short</TD>
                                    <TD style={{ ...styles.settlementInsideText, ...styles.boldText, justifyContent: "flex-end" }}>{parseFloat(data.totalShortOverNonCash).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                    <TD></TD>
                                    <TD></TD>
                                </TH>
                                <TH>
                                    <TD style={[styles.settlementInsideText]}>Cashier ID</TD>

                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>Short/Over</TD>
                                    <TD></TD>
                                    <TD style={[styles.settlementInsideText]}>
                                        <Text style={{ marginRight: 20 }}>Reason</Text>
                                    </TD>
                                </TH>
                                {data.shortOverNonCashDataReturned.map((item, index) => (
                                    <TR>
                                        <TD style={{ ...styles.settlementInsideText }}>{item.CashierID}</TD>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>_________________________</TD>
                                        <TD></TD>
                                        <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>_________________________</TD>
                                    </TR>
                                ))} */}
                                <TH style={{ marginTop: 10 }}>
                                    <TD style={{ ...styles.settlementInsideText }}>Cancelled Bill</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseInt(data.NonSalesDataReturned.cancelledTC)}</TD>
                                    <TD></TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end", ...styles.boldText }}>{parseFloat(data.NonSalesDataReturned.cancelledAmount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                            </Table>
                        </View>

                        <View style={styles.divider}>
                            <Text style={{ ...styles.bigTitle }}>TC</Text>
                        </View>
                        {/* line seperator */}
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={styles.lineSeperator} />
                        </View>
                        <View style={{ width: "93%" }}>
                            <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText, ...styles.boldText }}>Bill No. (Count)</TD>
                                    <TD style={{ ...styles.settlementInsideText, ...styles.boldText, justifyContent: "flex-end" }}>Qty</TD>
                                    <TD></TD>
                                    <TD></TD>
                                </TH>
                                <TR>
                                    <TD style={{ ...styles.settlementInsideText, ...styles.boldText }}>{parseInt(parseInt(parseInt(data.TCQty) - parseInt(data.totalNonSalesCount) - parseInt(data.NonSalesDataReturned.cancelledTC)))}</TD>
                                    <TD style={{ ...styles.settlementInsideText, ...styles.boldText, justifyContent: "flex-end" }}>{parseInt(parseInt(parseInt(data.OrderQty) - parseInt(data.totalNonSalesCount)) - parseInt(data.NonSalesDataReturned.cancelledTC))}</TD>
                                    <TD></TD>
                                    <TD></TD>
                                </TR>
                            </Table>
                        </View>
                        <View >
                            <Text style={{ ...styles.bigTitle }}>Cash Movement</Text>
                        </View>
                        {/* line seperator */}
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={styles.lineSeperator} />
                        </View>
                        <View style={{ width: "35%", marginTop: 10 }}>
                            <Table tdStyle={{ padding: '6px', borderWidth: 0 }}>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>Cash</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(data.CMCash).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>Float</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(data.CMFloat).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>Pick Up</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(data.CMPickUp).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                {/* line seperator */}
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <View style={styles.lineSeperator} />
                                </View>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>OverShort</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(data.CMOverShort).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                                <TH>
                                    <TD style={{ ...styles.settlementInsideText }}>NET COLLECTED AMT</TD>
                                    <TD style={{ ...styles.settlementInsideText, justifyContent: "flex-end" }}>{parseFloat(data.CMCash).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TD>
                                </TH>
                            </Table>
                        </View>
                        {/* Bigline Seperator */}
                        
                        {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            
                            <View style={styles.lineSeperator} />
                        </View>*/}
                    </View>
                    </View>
                </Page>
            </Document >
        </>
    )
}

export default AlohaDFComponent;