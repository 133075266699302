import { UnlockIcon } from "@chakra-ui/icons";
import {
    Flex, Spacer, HStack, Button, Avatar, useToast, Text, AvatarBadge, Heading, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Tab,
    Image,
    Tabs,
    TabIndicator,
    TabList

} from "@chakra-ui/react";
import { ArrowBackIcon } from '@chakra-ui/icons'
import { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { Link } from "react-router-dom"
import NavbarChangePassword from "./NavbarChangePassword";
import Banner from "../../Assets/Banner.png";

export default function NavBar({ inManagement, in404, tabIndicatorColor, logo, onTabClick }) {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState()
    const [id, setId] = useState()
    const [changePassModal, setChangePassModal] = useState(false)
    const toast = useToast();

    useEffect(() => {
        // Retrieve the user's email from localStorage
        const email = localStorage.getItem('email');
        setEmail(email);
        const role = localStorage.getItem("role")
        setRole(role)
        const id = localStorage.getItem("id")
        setId(id)
    }, []);

    const showToast = () => {
        Cookies.remove('accessToken');
        localStorage.removeItem("role")
        localStorage.removeItem("email")
        localStorage.removeItem("id")
        localStorage.removeItem("tableauToken")
        toast({
            title: 'Logged out',
            colorScheme: 'blue',
            description: 'Successfully logged out!',
            duration: 2000,
            isClosable: true,
            status: 'success',
            position: 'top',
            icon: <UnlockIcon />
        });

        setTimeout(() => {
            // Reload the page
            window.location.reload();
            //navigate('/');
        }, 1000);
    };

    const changePassmodalHandler = () => {
        setChangePassModal(prev => !prev)
    }
    const handleTabClick = (tab) => {
        onTabClick(tab)
    }

    return (
        <Flex
            as="nav"
            p="20px"
            alignItems="center"
            bg="white"
            boxShadow="md"
            zIndex="sticky"
            top="0"
            left="0"
            right="0"
            justifyContent={{ base: "center", sm: "space-between" }}
            w={"100%"}
            flexWrap="wrap" // Wrap content on smaller screens
        >
            {!tabIndicatorColor && <Heading as="h1" color="black">
                {inManagement || in404
                    ? <Link to={"/homepage"}>
                        <ArrowBackIcon />
                    </Link>
                    : <Image src={Banner} alt="Banner" boxSize="50px" width="450px" />}
            </Heading>}
            {tabIndicatorColor && <>
                <HStack align="center" display={"flex"} justify={"center"}>

                    <Link to="/homepage">
                        <Image src={logo} alt="Logo" boxSize="80px" />
                    </Link>
                </HStack>
                <Tabs position="relative" variant="unstyled" justifyContent={"center"}>
                    <TabList>
                        <Tab onClick={() => handleTabClick('Sales')} style={{ fontSize: "20px", marginRight: "20px", fontWeight: "500", }}> Daily Sales Report </Tab>
                        <Tab onClick={() => handleTabClick('Reports')} style={{ fontSize: "20px", fontWeight: "500" }}> BI Dashboard </Tab>
                    </TabList>
                    <TabIndicator mt="-1.5px" height="2px" bg={tabIndicatorColor} borderRadius="1px" />

                </Tabs>
            </>}


            <HStack spacing="20px" align="center">
                <Menu>
                    <MenuButton>
                        <Avatar src="https://bit.ly/broken-link" size="md" bg="blue.400" mt={{ base: 5, md: 0 }}>
                            <AvatarBadge width="1.3em" bg="teal.500">
                                <Text fontSize="xs" color="white"> 5 </Text>
                            </AvatarBadge>
                        </Avatar>
                    </MenuButton>
                    <MenuList>
                        <MenuItem isDisabled={true}>{email}</MenuItem>
                        {!inManagement && role === "admin" && <MenuItem as={"b"}><Link to={"/brandManagement"}>Brand Management</Link></MenuItem>}
                        {!inManagement && role === "admin" && <MenuItem as={"b"}><Link to={"/userManagement"}>User Management</Link></MenuItem>}
                        <MenuItem onClick={changePassmodalHandler} as={"b"}>Change Password</MenuItem>
                        <MenuItem onClick={showToast} as={"b"}>Log Out</MenuItem>
                    </MenuList>
                </Menu>

            </HStack>
            <NavbarChangePassword modlaState={changePassModal} modalClose={changePassmodalHandler} idLogin={id} />
        </Flex>
    );
}
