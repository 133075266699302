import React, { useState } from 'react';
//import KR_Sidebar from '../../Access_Sidebar/KR_SideBar';
// import KR_NavBar from '../../../../Access_NavBar/KR_NavBar';
import { Flex, Box } from '@chakra-ui/react';
import kr_cover from '../../../Assets/kr_cover.png';
import kr_logo from '../../../Assets/kr_logo.png';
import NavBar from '../../../Components/Navbar/NavBar';
import Sales from "./SalesKR"
import Reports from "./ReportsKR"
import About from './AboutKR';
import img from "../../../Assets/boxes.jpg"

function KR() {
    const [selectedOption, setSelectedOption] = useState('Sales');
    const handleTabClick = (tab) => {
        setSelectedOption(tab);
    };
    return (
        <div>
            {/* <KR_NavBar onTabClick={handleTabClick} logo={kr_logo} tabIndicatorColor="yellow.500" /> */}
            <NavBar tabIndicatorColor="yellow.500" logo={kr_logo} onTabClick={handleTabClick} />
            <Flex justifyContent="center" alignItems="center" position="relative">
                {/* Place the image with a lower zIndex */}
                {/* <Box
                    mb={40}
                    style={{
                        backgroundImage: `url(${kr_cover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: "100%",
                        height: '250px'
                    }}
                /> */}
            </Flex>
            <Box display={"flex"} alignItems={"center"} bgImage={`url(${img})`} bgRepeat={"no-repeat"} bgSize={"cover"} justifyContent={"center"} w={"100%"}>
                <Box w={"90%"}>
                    {/* Render content based on the selected option */}
                    {selectedOption === 'Sales' && <Sales content={"Sales KR"} color={"yellow"} />}
                    {selectedOption === 'Reports' && <Reports content={"Reports KR"} />}
                </Box>
            </Box>
        </div>
    );
}

export default KR;
