import React, { useState, useEffect } from 'react';
import { Box, Button, Spinner, useMediaQuery } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, useToast } from '@chakra-ui/react';
import { RepeatIcon } from "@chakra-ui/icons"

import TableauEmbedKRChannel from '../../../Components/EmbedKRChannel/EmbedKRChannel';
import TableauEmbedKRDSR from '../../../Components/EmbedKRDSR/EmbedKRDSR';
import TableauEmbedKRHourly from '../../../Components/EmbedKRHourly/EmbedKRHourly';
import TableauEmbedKRPMIX from '../../../Components/EmbedKRPMIX/EmbedKRPMIX';
import TableauEmbedKRTender from '../../../Components/EmbedKRTender/EmbedKRTender';
import TableauEmbedKRWeekly from '../../../Components/EmbedKRWeekly/EmbedKRWeekly';
import TableauEmbedKRNPS from '../../../Components/EmbedKRNPS/EmbedKRNPS';
import TableauEmbedPlay from '../../../Components/EmbedKRPlay/EmbedKRPlay';

//MObile Tableau Embeds
import TableauEmbedKRChannelMobile from '../../../Components/EmbedKRChannel/EmbedKRChannelMobile';
import TableauEmbedKRDSRMobile from '../../../Components/EmbedKRDSR/EmbedKRDSRMobile';
import TableauEmbedKRHourlyMobile from '../../../Components/EmbedKRHourly/EmbedKRHourlyMobile';
import TableauEmbedKRPMIXMobile from '../../../Components/EmbedKRPMIX/EmbedKRPMixMobile';
import TableauEmbedKRTenderMobile from '../../../Components/EmbedKRTender/EmbedKRTenderMobile';
import TableauEmbedKRWeeklyMobile from '../../../Components/EmbedKRWeekly/EmbedKRWeeklyMobile';
import TableauEmbedKRNPSMobile from '../../../Components/EmbedKRNPS/EmbedKRNPSMobile';

import axios from 'axios';

const KRReports = ({ content }) => {
    const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");
    const [refreshPage, setRefreshPage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const [key, setKey] = useState(Date.now());
    const toast = useToast();
    const handleRefreshTokenTableauButton = async () => {
        try {
                setLoading(true)
                const resJWTTableau = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/generatejwt`,
                        { withCredentials: true })
                localStorage.setItem("tableauToken", resJWTTableau.data)
                setRefreshPage(prev => !prev)
                setKey(Date.now())
                setLoading(false)

        } catch (err) {
                setLoading(false)
                toast({
                        title: 'Error',
                        position: "top-right",
                        description: err.response.data.message,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                })
        }
    }
    useEffect(() => {
        const timer = setTimeout(() => {
                setFirstLoad(false);
        }, 1000 * 3); // 10 seconds

        return () => clearTimeout(timer);
    }, [refreshPage])



    return (
        <>
                <div key={key}>
                        <Box mt={4} mb={2}>
                                <Button colorScheme="blue" onClick={handleRefreshTokenTableauButton} isDisabled={loading}>{loading ? <Spinner /> : <>Refresh <RepeatIcon marginLeft={2} /></>}</Button>
                        </Box>
                        <Tabs isFitted variant='enclosed'>
                                <TabList mb='1em' style={{
                                        overflowX: 'auto',
                                        whiteSpace: 'nowrap',
                                        scrollbarWidth: 'none',
                                        msOverflowStyle: 'none',
                                }}>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Product Mix</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Hourly</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Weekly</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Tender</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Channel</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>DSR</Tab>
                                        <Tab _selected={{ color: 'white', bg: 'blue.500' }}>NPS</Tab>
                                </TabList>
                                <TabPanels height={{
                                        base: '100%', // 0-48em
                                        md: '50%', // 48em-80em,
                                        xl: '25%', // 80em+
                                }} width="100%" m={0} p={0}>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRPMIXMobile />
                                                        ) : (
                                                                <TableauEmbedKRPMIX />
                                                        )
                                                        )}
                                        </TabPanel>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRHourlyMobile />
                                                        ) : (
                                                                <TableauEmbedKRHourly />
                                                        )

                                                        )}
                                        </TabPanel>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRWeeklyMobile />
                                                        ) : (
                                                                <TableauEmbedKRWeekly />
                                                        )

                                                        )}
                                        </TabPanel>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRTenderMobile />
                                                        ) : (
                                                                <TableauEmbedKRTender />
                                                        )
                                                        )}
                                        </TabPanel>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRChannelMobile />
                                                        ) : (
                                                                <TableauEmbedKRChannel />
                                                        )
                                                        )}
                                        </TabPanel>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRDSRMobile />
                                                        ) : (
                                                                <TableauEmbedKRDSR />
                                                        )
                                                        )}
                                        </TabPanel>
                                        <TabPanel m={0} p={0}>
                                                {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                        (isSmallerThan600 ? (
                                                                <TableauEmbedKRNPSMobile />
                                                        ) : (
                                                                <TableauEmbedKRNPS />
                                                        )
                                                        )}
                                        </TabPanel>
                                        
                                </TabPanels>
                        </Tabs>
                </div>

        </>
    );
}

export default KRReports