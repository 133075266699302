export function processHeaderData(data) {
    const openingData = data.gross_opening
    const closingData = data.gross_closing
    const totalGrossAmt = data.gross
    const totalvat = data.vat
    return {
        openingData,
        closingData,
        totalGrossAmt,
        totalvat
    }
}
export function processSalesData(data) {
    let salesData = {
        "sales": { "VAT": 0, "VATE": 0 },
        "netsales": { "VAT": 0, "VATE": 0 },
        "grosssales": { "VAT": 0, "VATE": 0 },
        "discount": { "VAT": 0, "VATE": 0 },
        "qty": { "VAT": 0, "VATE": 0 }
    };

    if (Array.isArray(data)) {
        data.forEach(item => {
            let taxcode = item.taxcode;
            salesData.sales[taxcode] += parseFloat(item.sales.replace(/,/g, ''));
            salesData.netsales[taxcode] += parseFloat(item.netsales.replace(/,/g, ''));
            salesData.grosssales[taxcode] += parseFloat(item.grossales.replace(/,/g, ''));
            salesData.discount[taxcode] += parseFloat(item.discount.replace(/,/g, ''));
            salesData.qty[taxcode] += parseFloat(item.qty);
        });
    }
    return { salesData };
}
export function processdiscounts(data) {
    let dataDiscount = [];
    let dataDiscountVAT = 0;
    let dataDiscountVATE = 0;

    data.forEach(item => {
        let discount = parseFloat(item.discount.replace(/,/g, ''));
        let qty = parseFloat(item.qty.replace(/,/g, ''));

        if (item.taxcode === "VATE") {
            dataDiscountVATE += discount;
        } else {
            dataDiscountVAT += discount;
        }

        let existingItem = dataDiscount.find(i => i.discDesc === item.discdesc);
        if (existingItem) {
            if (item.taxcode === "VATE") {
                existingItem.VATE += discount;
                existingItem.VATEQty += qty;
            } else {
                existingItem.VAT += discount;
                existingItem.VATQty += qty;
            }
        } else {
            let newItem = {
                discDesc: item.discdesc,
                VAT: item.taxcode !== "VATE" ? discount : 0,
                VATE: item.taxcode === "VATE" ? discount : 0,
                VATQty: item.taxcode !== "VATE" ? qty : 0,
                VATEQty: item.taxcode === "VATE" ? qty : 0
            };
            dataDiscount.push(newItem);
        }
    });

    return {
        dataDiscount,
        dataDiscountVAT,
        dataDiscountVATE
    };
}

export function processGrossBreakDown(data) {
    let dataGrossBreakDownReturned = [];
    let totalGrossBreakDownVAT = 0
    let totalGrossBreakDownVATE = 0
    data.forEach(item => {
        try {
            let existingItem = dataGrossBreakDownReturned.find(i => i.OrderTypeDesc === item.OrderTypeDesc);
            if (existingItem) {
                if (item.taxcode === "VATE") {
                    existingItem.VATE += parseFloat(item.grossales.replace(/,/g, ''));
                    existingItem.QtyVATE += parseFloat(item.qty.replace(/,/g, ''));
                    totalGrossBreakDownVATE += parseFloat(item.grossales.replace(/,/g, ''))
                }
                else {
                    existingItem.VAT += parseFloat(item.grossales.replace(/,/g, ''));
                    existingItem.QtyVAT += parseFloat(item.qty.replace(/,/g, ''));
                    totalGrossBreakDownVAT += parseFloat(item.grossales.replace(/,/g, ''))
                }
            } else {
                if (item.taxcode === "VATE") {
                    dataGrossBreakDownReturned.push({
                        OrderTypeDesc: item.OrderTypeDesc,
                        VAT: 0,
                        VATE: parseFloat(item.grossales.replace(/,/g, '')),
                        QtyVAT: 0,
                        QtyVATE: parseFloat(item.qty.replace(/,/g, ''))
                    });
                    totalGrossBreakDownVATE += parseFloat(item.grossales.replace(/,/g, ''))
                } else {
                    dataGrossBreakDownReturned.push({
                        OrderTypeDesc: item.OrderTypeDesc,
                        VAT: parseFloat(item.grossales.replace(/,/g, '')),
                        VATE: 0,
                        QtyVAT: parseFloat(item.qty.replace(/,/g, '')),
                        QtyVATE: 0
                    });
                    totalGrossBreakDownVAT += parseFloat(item.grossales.replace(/,/g, ''))
                }
            }
        } catch (err) {
            console.log(err)
        }

    });
    return {
        dataGrossBreakDownReturned,
        totalGrossBreakDownVAT: totalGrossBreakDownVAT.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        totalGrossBreakDownVATE: totalGrossBreakDownVATE.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}
export function processPaymentData(data) {
    const NONSALES = ["Employee Meals"]
    let paymentDataReturned = []
    let NonSalesPaymentDataReturned = []
    let totalNonSalesCount = 0
    let totalCash = 0
    let totalNonCash = 0
    let ChangeAmount = 0
    let TenderAmount = 0
    let totalNonCashQty = 0
    let VoidAmount = 0
    let VoidCount = 0
    data.result.forEach(item => {
        if (item.CancelFlag === "true") {
            VoidAmount += parseFloat(item.TenderAmt)
            VoidCount++
        } else {
            if (NONSALES.includes(item.TenderDesc)) {
                console.log(item.TenderDesc)
                let existingNonSales = NonSalesPaymentDataReturned.find(i => i.TenderDesc === item.TenderDesc)
                if (existingNonSales) {
                    existingNonSales.Amount += parseFloat(item.TenderAmt)
                    existingNonSales.Quantity++
                    totalNonSalesCount++
                } else {
                    NonSalesPaymentDataReturned.push({
                        TenderDesc: item.TenderDesc,
                        Amount: parseFloat(item.TenderAmt),
                        Quantity: 1
                    })
                    totalNonSalesCount++
                }
            } else {
                let existingItem = paymentDataReturned.find(i => i.TenderDesc === item.TenderDesc);

                if (existingItem) {
                    if (item.TenderDesc === "Cash") {
                        totalCash += parseFloat(item.TenderAmt) - parseFloat(item.ChangeAmt)
                        existingItem.Amount += parseFloat(item.TenderAmt)
                        ChangeAmount += parseFloat(item.ChangeAmt)
                        TenderAmount += parseFloat(item.TenderAmt)
                        existingItem.Quantity++
                    } else {
                        totalNonCash += parseFloat(item.TenderAmt)
                        existingItem.Amount += parseFloat(item.TenderAmt)
                        ChangeAmount += parseFloat(item.ChangeAmt)
                        TenderAmount += parseFloat(item.TenderAmt)
                        totalNonCashQty++
                        existingItem.Quantity++
                    }
                } else {
                    if (item.TenderDesc === "Cash") {
                        totalCash += parseFloat(item.TenderAmt) - parseFloat(item.ChangeAmt)
                        paymentDataReturned.push({
                            TenderDesc: item.TenderDesc,
                            Amount: parseFloat(item.TenderAmt),
                            Quantity: 1
                        })
                        ChangeAmount += parseFloat(item.ChangeAmt)
                        TenderAmount += parseFloat(item.TenderAmt)
                    } else {
                        totalNonCash += parseFloat(item.TenderAmt)
                        paymentDataReturned.push({
                            TenderDesc: item.TenderDesc,
                            Amount: parseFloat(item.TenderAmt),
                            Quantity: 1
                        })
                        totalNonCashQty++
                        ChangeAmount += parseFloat(item.ChangeAmt)
                        TenderAmount += parseFloat(item.TenderAmt)
                    }
                }
            }


        }

    })

    return {
        totalPaymentCash: (parseFloat(totalCash) - parseFloat(VoidAmount)).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        totalPaymentNonCash: parseFloat(totalNonCash).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        paymentDataReturned,
        ChangeAmount: parseFloat(ChangeAmount).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        TenderAmount: parseFloat(TenderAmount).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        VoidPaymentAmount: parseFloat(VoidAmount).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        VoidPaymentCount: VoidCount,
        totalNonCashQty,
        NonSalesPaymentDataReturned,
        totalNonSalesCount
    }
}
export function processCashierBreakDown(data) {
    let cashierBreakDownReturned = []
    let totalCashierAmount = 0
    let totalShortOver = 0
    let shortOverDataReturned = []
    let totalShortOverNonCash = 0
    let shortOverNonCashDataReturned = []
    let CMCash = 0
    let CMFloat = 0
    let CMPickUp = 0
    let CMOverShort = 0

    data.forEach(innerArray => {
        innerArray.forEach(item => {
            if (item.TenderCode === "CASH") {
                cashierBreakDownReturned.push({ CashierID: item.CashierID + "  " + item.shortname, Amount: parseFloat(item.TndNetAmt.replace(/,/g, '')) })
                totalCashierAmount += parseFloat(item.TndNetAmt.replace(/,/g, ''))
                totalShortOver += parseFloat(item.PickupDiff.replace(/,/g, ''))
                shortOverDataReturned.push({ CashierID: item.CashierID + "  " + item.shortname, Amount: parseFloat(item.PickupDiff.replace(/,/g, '')) })
                CMCash += parseFloat(item.TndNetAmt.replace(/,/g, ''))
                CMFloat += parseFloat(item.TotalFloat.replace(/,/g, ''))
                CMPickUp += parseFloat(item.TotalPickUp.replace(/,/g, ''))
                CMOverShort += parseFloat(item.PickupDiff.replace(/,/g, ''))
            } else if (item.TenderCode !== "EMEAL") {
                totalShortOverNonCash += parseFloat(item.PickupDiff.replace(/,/g, ''))
                let cashier = shortOverNonCashDataReturned.find(cashier => cashier.CashierID === item.CashierID + "  " + item.shortname);
                if (cashier) {
                    cashier.Amount += parseFloat(item.PickupDiff.replace(/,/g, ''));
                } else {
                    shortOverNonCashDataReturned.push({ CashierID: item.CashierID + "  " + item.shortname, Amount: parseFloat(item.PickupDiff.replace(/,/g, '')) })
                }
            }
        })
    })
    return {
        cashierBreakDownReturned,
        totalCashierAmount,
        totalShortOver,
        shortOverDataReturned,
        totalShortOverNonCash,
        shortOverNonCashDataReturned,
        CMCash,
        CMFloat,
        CMOverShort,
        CMPickUp
    }
}

export function processBillNoAndQty(data) {
    let TCQty = 0
    let OrderQty = 0
    data.forEach(item => {
        TCQty++
        OrderQty += parseFloat(item.OrderQty)
    })
    return {
        TCQty,
        OrderQty
    }

}
export function processNonSalesData(data) {
    let NonSalesDataReturned = {
        "NonSales": 0,
        "NonSalesCount": 0,
        "cancelledAmount": 0,
        "cancelledTC": 0,
        "shortover": 0,
        "voids": 0,
        "voidsCount": 0,

    }
    data.forEach(item => {
        NonSalesDataReturned.NonSales += item.NONSALES ? parseFloat(item.NONSALES.replace(/,/g, '')) : 0;
        NonSalesDataReturned.NonSalesCount += item.NSTC ? parseFloat(item.NSTC) : 0;
        NonSalesDataReturned.cancelledAmount += item.CancelledBill ? parseFloat(item.CancelledBill.replace(/,/g, '')) : 0;
        NonSalesDataReturned.cancelledTC += item.cancelledTC ? parseFloat(item.cancelledTC) : 0;
        NonSalesDataReturned.shortover += item.shortover ? parseFloat((parseFloat(NonSalesDataReturned.shortover) + parseFloat(item.shortover.replace(/,/g, ''))).toFixed(2)) : 0
        NonSalesDataReturned.voids += item.voids ? parseFloat(item.voids) : 0;
        NonSalesDataReturned.voidsCount += item.voidQty ? parseFloat(item.voidQty) : 0;
    })
    return {
        NonSalesDataReturned
    }
}

export function processNonSalesDataWithTenderDesc(data) {
    const NonSalesWithTenderDesc = [];
    let totalNonSalesCounter = 0;
    let totalNonSales = 0;

    data.forEach((item, index) => {
        if (item && item.TenderDesc && item.NetAmount) { // Check if item and required properties are not null or undefined
            let existingItem = NonSalesWithTenderDesc.find(i => i.TenderDesc === item.TenderDesc);
            if (existingItem) {
                existingItem.Amount += parseFloat(item.NetAmount.replace(/,/g, ''));
                existingItem.Quantity++;
            } else {
                NonSalesWithTenderDesc.push({
                    TenderDesc: item.TenderDesc,
                    Amount: parseFloat(item.NetAmount.replace(/,/g, '')),
                    Quantity: 1
                });
            }
            totalNonSalesCounter++;
            totalNonSales += parseFloat(item.NetAmount.replace(/,/g, ''));
        }
    });

    return {
        NonSalesWithTenderDesc,
        totalNonSales,
        totalNonSalesCounter
    };
}

export function processAlohaHeaderReading(data) {
    const openingData = data[0].gross_opening
    const closingData = data[0].gross_closing
    const totalGrossAmt = data[0].gross
    const totalvat = data[0].vat
    return {
        openingData,
        closingData,
        totalGrossAmt,
        totalvat
    }
}

export function processAlohaSalesData(data) {
    let salesData = {
        "sales": { "VAT": 0, "VATE": 0 },
        "netsales": { "VAT": 0, "VATE": 0 },
        "grosssales": { "VAT": 0, "VATE": 0 },
        "discount": { "VAT": 0, "VATE": 0 },
        "qty": { "VAT": 0, "VATE": 0 }
    };
    data.forEach((item) => {
        //tax code 0 VATE
        //taxcode 1 Vat
        if (parseInt(item["taxcode"]) === 1) {
            salesData.sales.VAT += parseFloat(item["sales"]);
            salesData.netsales.VAT += parseFloat(item["netsales"]);
            salesData.grosssales.VAT += parseFloat(item["grossprice"]);
            salesData.discount.VAT += parseFloat(item["discount"]);
            salesData.qty.VAT += parseInt(item["qty"]);
        } else if (parseInt(item["taxcode"]) === 2 || parseInt(item["taxcode"]) === 0) {
            salesData.sales.VATE += parseFloat(item["sales"]);
            salesData.netsales.VATE += parseFloat(item["netsales"]);
            salesData.grosssales.VATE += parseFloat(item["grossprice"]);
            salesData.discount.VATE += parseFloat(item["discount"]);
            salesData.qty.VATE += parseInt(item["qty"]);
        }
    })
    return { salesData }
}

export function proccessAlohaDiscount(data) {
    let dataDiscount = [];
    let dataDiscountVAT = 0;
    let dataDiscountVATE = 0;
    data.forEach((item) => {
        let discount = parseFloat(item.discount);
        let qty = parseFloat(item.qty);
        let taxcode = item.taxcode;

        if (taxcode === 1) {
            dataDiscountVAT += discount;
        } else if (taxcode === 2 || taxcode === 0) {
            dataDiscountVATE += discount;
        }
        let existingItem = dataDiscount.find(i => i.discDesc === item.discdesc.trim());
        if (existingItem) {
            if (taxcode === 1) {
                existingItem.VAT += discount;
                existingItem.VATQty += qty;
            } else if (taxcode === 0 || taxcode === 2) {
                existingItem.VATE += discount;
                existingItem.VATEQty += qty;
            }
        } else {
            let newItem = {
                discDesc: item.discdesc.trim(),
                VAT: taxcode === 1 ? discount : 0,
                VATE: taxcode === 0 || taxcode === 2 ? discount : 0,
                VATQty: taxcode === 1 ? qty : 0,
                VATEQty: taxcode === 0 || taxcode === 2 ? qty : 0
            };
            dataDiscount.push(newItem);
        }
    });

    return { dataDiscount, dataDiscountVAT, dataDiscountVATE };
}
export function processAlohaGrossBreakDown(data) {
    let dataGrossBreakDownReturned = [];
    let totalGrossBreakDownVAT = 0;
    let totalGrossBreakDownVATE = 0;

    data.forEach(item => {
        try {
            // Clean up the whitespace from OrderTypeDesc
            let orderTypeDesc = item.ordertypedesc.trim();
            let taxCode = item.taxcode;

            // Initialize the gross sales and quantity
            let grossSales = parseFloat(item.grossales);
            let qty = parseFloat(item.qty);
            let existingItem = dataGrossBreakDownReturned.find(i => i.OrderTypeDesc === orderTypeDesc);
            if (existingItem) {
                if (taxCode === 1) { // VAT
                    existingItem.VAT += grossSales;
                    existingItem.QtyVAT += qty;
                    totalGrossBreakDownVAT += grossSales;
                } else if (taxCode === 0 || taxCode === 2) { // VATE
                    existingItem.VATE += grossSales;
                    existingItem.QtyVATE += qty;
                    totalGrossBreakDownVATE += grossSales;
                }
            } else {
                let newItem = {
                    OrderTypeDesc: orderTypeDesc,
                    VAT: taxCode === 1 ? grossSales : 0,
                    VATE: (taxCode === 0 || taxCode === 2) ? grossSales : 0,
                    QtyVAT: taxCode === 1 ? qty : 0,
                    QtyVATE: (taxCode === 0 || taxCode === 2) ? qty : 0
                };
                dataGrossBreakDownReturned.push(newItem);

                if (taxCode === 1) {
                    totalGrossBreakDownVAT += grossSales;
                } else if (taxCode === 0 || taxCode === 2) {
                    totalGrossBreakDownVATE += grossSales;
                }
            }
        } catch (err) {
            console.log(err);
        }
    });

    return {
        dataGrossBreakDownReturned,
        totalGrossBreakDownVAT,
        totalGrossBreakDownVATE
    };
}

export function processAlohaCashierBreakDown(data) {
    let cashierBreakDownReturned = [];
    let totalCashierAmount = 0;
    let totalShortOver = 0;
    let shortOverDataReturned = [];
    let totalShortOverNonCash = 0;
    let shortOverNonCashDataReturned = [];
    let CMCash = 0;
    let CMFloat = 0;
    let CMPickUp = 0;
    let CMOverShort = 0;

    data.forEach(item => {
        if (item.tendercode === "CASH") {
            cashierBreakDownReturned.push({
                CashierID: item.cashierid + "  " + item.shortname.trim(),
                Amount: parseFloat(item.tndnetamt)
            });
            totalCashierAmount += parseFloat(item.tndnetamt);
            totalShortOver += parseFloat(item.PickUpDiff);
            shortOverDataReturned.push({
                CashierID: item.cashierid + "  " + item.shortname.trim(),
                Amount: parseFloat(item.PickUpDiff)
            });
            CMCash += parseFloat(item.tndnetamt);
            CMFloat += parseFloat(item.TotalFloat);
            CMPickUp += parseFloat(item.TotalPickUp ?? 0);  // Handle null values
            CMOverShort += parseFloat(item.PickUpDiff);
        } else if (item.tendercode !== "EMEAL") {
            totalShortOverNonCash += parseFloat(item.PickUpDiff);
            let cashier = shortOverNonCashDataReturned.find(cashier => cashier.CashierID === item.cashierid + "  " + item.shortname.trim());
            if (cashier) {
                cashier.Amount += parseFloat(item.PickUpDiff);
            } else {
                shortOverNonCashDataReturned.push({
                    CashierID: item.cashierid + "  " + item.shortname.trim(),
                    Amount: parseFloat(item.PickUpDiff)
                });
            }
        }
    });

    return {
        cashierBreakDownReturned,
        totalCashierAmount,
        totalShortOver,
        shortOverDataReturned,
        totalShortOverNonCash,
        shortOverNonCashDataReturned,
        CMCash,
        CMFloat,
        CMOverShort,
        CMPickUp
    };
}
export function processAlohaPaymentData(data) {
    const NONSALES = ["Employee Meals"];
    let paymentDataReturned = [];
    let NonSalesPaymentDataReturned = [];
    let totalNonSalesCount = 0;
    let totalCash = 0;
    let totalNonCash = 0;
    let ChangeAmount = 0;
    let TenderAmount = 0;
    let totalNonCashQty = 0;
    let VoidAmount = 0;
    let VoidCount = 0;

    data.forEach(item => {
        if (item.cancelflag === "True") {
            VoidAmount += parseFloat(item.tenderamt);
            VoidCount++;
        } else {
            let tenderDescTrimmed = item.tenderdesc.trim();  // Trim any extra spaces
            if (NONSALES.includes(tenderDescTrimmed)) {
                let existingNonSales = NonSalesPaymentDataReturned.find(i => i.TenderDesc === tenderDescTrimmed);
                if (existingNonSales) {
                    existingNonSales.Amount += parseFloat(item.tenderamt);
                    existingNonSales.Quantity++;
                    totalNonSalesCount++;
                } else {
                    NonSalesPaymentDataReturned.push({
                        TenderDesc: tenderDescTrimmed,
                        Amount: parseFloat(item.tenderamt),
                        Quantity: 1
                    });
                    totalNonSalesCount++;
                }
            } else {
                let existingItem = paymentDataReturned.find(i => i.TenderDesc === tenderDescTrimmed);
                if (existingItem) {
                    if (tenderDescTrimmed === "CASH") {
                        totalCash += parseFloat(item.tenderamt) - parseFloat(item.changeamt);
                        existingItem.Amount += parseFloat(item.tenderamt);
                        ChangeAmount += parseFloat(item.changeamt);
                        TenderAmount += parseFloat(item.tenderamt);
                        existingItem.Quantity++;
                    } else {
                        totalNonCash += parseFloat(item.tenderamt);
                        existingItem.Amount += parseFloat(item.tenderamt);
                        ChangeAmount += parseFloat(item.changeamt);
                        TenderAmount += parseFloat(item.tenderamt);
                        totalNonCashQty++;
                        existingItem.Quantity++;
                    }
                } else {
                    if (tenderDescTrimmed === "CASH") {
                        totalCash += parseFloat(item.tenderamt) - parseFloat(item.changeamt);
                        paymentDataReturned.push({
                            TenderDesc: tenderDescTrimmed,
                            Amount: parseFloat(item.tenderamt),
                            Quantity: 1
                        });
                        ChangeAmount += parseFloat(item.changeamt);
                        TenderAmount += parseFloat(item.tenderamt);
                    } else {
                        totalNonCash += parseFloat(item.tenderamt);
                        paymentDataReturned.push({
                            TenderDesc: tenderDescTrimmed,
                            Amount: parseFloat(item.tenderamt),
                            Quantity: 1
                        });
                        totalNonCashQty++;
                        ChangeAmount += parseFloat(item.changeamt);
                        TenderAmount += parseFloat(item.tenderamt);
                    }
                }
            }
        }
    });

    return {
        totalPaymentCash: (parseFloat(totalCash) - parseFloat(VoidAmount)).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        totalPaymentNonCash: parseFloat(totalNonCash).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        paymentDataReturned,
        ChangeAmount: parseFloat(ChangeAmount).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        TenderAmount: parseFloat(TenderAmount).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        VoidPaymentAmount: parseFloat(VoidAmount).toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        VoidPaymentCount: VoidCount,
        totalNonCashQty,
        NonSalesPaymentDataReturned,
        totalNonSalesCount
    };
}
export function processAlohaNonSalesData(data) {
    let NonSalesDataReturned = {
        "NonSales": 0,
        "NonSalesCount": 0,
        "cancelledAmount": 0,
        "cancelledTC": 0,
        "shortover": 0,
        "voids": 0,
        "voidsCount": 0,
    };

    data.forEach(item => {
        NonSalesDataReturned.NonSales += item.nonsales ? parseFloat(item.nonsales) : 0;
        NonSalesDataReturned.NonSalesCount += item.nstc ? parseFloat(item.nstc) : 0;
        NonSalesDataReturned.cancelledAmount += item.CancelledBill ? parseFloat(item.CancelledBill) : 0;
        NonSalesDataReturned.cancelledTC += item.CancelledTC ? parseFloat(item.CancelledTC) : 0;
        NonSalesDataReturned.shortover += item.shortover ? parseFloat((parseFloat(NonSalesDataReturned.shortover) + parseFloat(item.shortover)).toFixed(2)) : 0;
        NonSalesDataReturned.voids += item.voids ? parseFloat(item.voids) : 0;
        NonSalesDataReturned.voidsCount += item.voidqty ? parseFloat(item.voidqty) : 0;
    });

    return {
        NonSalesDataReturned
    };
}

export function processAlohaNonSalesDataWithTenderDesc(data) {
    const NonSalesWithTenderDesc = [];
    let totalNonSalesCounter = 0;
    let totalNonSales = 0;
    data.forEach(item => {
        if (item && item.tenderdesc && item.netamount) { // Check if item and required properties are not null or undefined
            let existingItem = NonSalesWithTenderDesc.find(i => i.TenderDesc.trim() === item.tenderdesc.trim());
            if (existingItem) {
                existingItem.Amount += parseFloat(item.netamount);
                existingItem.Quantity++;
            } else {
                NonSalesWithTenderDesc.push({
                    TenderDesc: item.tenderdesc.trim(), // Trim any extra spaces
                    Amount: parseFloat(item.netamount),
                    Quantity: 1
                });
            }
            totalNonSalesCounter++;
            totalNonSales += parseFloat(item.netamount);
        }
    });

    return {
        NonSalesWithTenderDesc,
        totalNonSales,
        totalNonSalesCounter
    };
}


export function processAlohaBillNOQTY(data) {
    let TCQty = 0;
    let OrderQty = 0;
    data.forEach(item => {
        TCQty++;
        OrderQty += parseFloat(item.orderqty);
    });
    return {
        TCQty,
        OrderQty
    };
}

export function processAlohaNonSales(data) {
    let nonSalesData = {
        nonSalesArray: [],
        totalNonSales: 0,
        totalNonSalesCount: 0
    };
    data.forEach((non) => {
        let existingItem = nonSalesData.nonSalesArray.find((item) => item.tenderDesc === non.TendeDesc);
        if (existingItem) {
            existingItem.netAmount += non.NetAmount;
            existingItem.qty += 1;
        } else {
            nonSalesData.nonSalesArray.push({
                tenderDesc: non.TendeDesc,
                netAmount: non.NetAmount,
                qty: 1
            });
        }
        nonSalesData.totalNonSales += non.NetAmount;
        nonSalesData.totalNonSalesCount += 1;
    });
    return { nonSalesData }
}