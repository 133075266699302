import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    FormControl,
    Input,
    useToast,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    ButtonGroup,
    Spinner
} from '@chakra-ui/react'
import DataTable from "react-data-table-component"
import axios from 'axios'

const DeactivateUserModal = ({ modalOpen, modalClose, trigger }) => {
    const toast = useToast()
    const [foundUser, setFoundUser] = useState([])
    const [deactivateButton, setDeactivateButton] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [userNameorId, setUserNameOrId] = useState("")
    const [deactivating, setDeactivating] = useState(false)
    const [checkUser, setCheckUser] = useState(false)
    const [isTouched, setIstouched] = useState(false)
    useEffect(() => {
        if (!modalOpen) {
            setUserNameOrId("")
            setFoundUser("")
        }
    }, [modalOpen])
    const validateHandler = async () => {
        setCheckUser(true)
        if (!userNameorId) {
            toast({
                title: 'Error',
                position: "top-right",
                description: "UserName or ID must be provided.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            return setCheckUser(false)
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/validateUser`, {
                userNameOrId: userNameorId
            })
            setFoundUser([res.data.user])
            setDeactivateButton(true)
            setIstouched(false)
            toast({
                title: 'Success',
                position: "top-right",
                description: "User found.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setCheckUser(false)
        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setCheckUser(false)
        }

    }
    const confirmModalState = () => {
        setConfirmModal(prev => !prev)
    }

    const handleDeactivateUser = async () => {
        setDeactivating(true)
        try {
            const res = await axios.delete(`${process.env.REACT_APP_BACKEND_API_URL}/adminDeleteUser`, {
                data: {
                    id: foundUser[0].idlogin
                },
                withCredentials: true
            })
            console.log(res)
            toast({
                title: 'Success',
                position: "top-right",
                description: "Successfully Deactivated the user's account.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setConfirmModal(prev => !prev)
            setDeactivating(false)
            modalClose()
            trigger()
        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setDeactivating(false)
        }
    }
    const handleChangeInput = (e) => {
        setIstouched(true)
        setUserNameOrId(e.target.value)

    }
    return (<>
        <Modal isOpen={modalOpen} onClose={modalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text color="red" style={{ display: 'inline' }}>Deactivate</Text> User
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mt={4}>
                        <Text as={"b"}>ID or Username of the user you want to</Text>
                        <Text ml={1} as={"b"} color="red" style={{ display: 'inline' }}>Deactivate</Text>
                        <Input mt={2} placeholder='ID or Username' value={userNameorId} onChange={handleChangeInput} name="fname" />
                    </FormControl>
                    {foundUser && <DataTable
                        columns={[
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>ID</div>
                                ),
                                selector: (row) => row.idlogin,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Username</div>
                                ),
                                selector: (row) => row.username,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>First name</div>
                                ),
                                selector: (row) => row.firstname,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Last name</div>
                                ),
                                selector: (row) => row.lastname,
                                sortable: true,
                                filterable: true,
                            },

                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Department</div>
                                ),
                                selector: (row) => row.department,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Role</div>
                                ),
                                selector: (row) => row.role,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Ownership</div>
                                ),
                                selector: (row) => row.ownership,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Access</div>
                                ),
                                selector: (row) => row.access.join(', '),
                                sortable: true,
                                filterable: true,
                            },
                        ]}
                        data={foundUser}
                        searchable
                        //  customFilterText={customFilterText}
                        highlightOnHover
                    />}
                </ModalBody>
                <ModalFooter display={"flex"} justifyContent={"space-between"}>
                    <Button colorScheme='green' onClick={validateHandler} isDisabled={confirmModal || checkUser}>Check user</Button>
                    <Popover
                        returnFocusOnClose={false}
                        isOpen={confirmModal}
                        onClose={confirmModalState}
                        placement='right'
                        closeOnBlur={false}
                    >
                        <PopoverTrigger>
                            <Button colorScheme='red' onClick={confirmModalState} isDisabled={confirmModal || !deactivateButton || isTouched}>{checkUser ? <Spinner /> : "Deactivate"}</Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverHeader fontWeight='semibold'>Confirmation</PopoverHeader>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                                Are you sure you want to delete user: <Text as={"b"} style={{ display: 'inline' }}>{foundUser && foundUser[0]?.username}</Text>
                            </PopoverBody>
                            <PopoverFooter display='flex' justifyContent='flex-end'>
                                <ButtonGroup size='sm'>
                                    <Button variant='outline' onClick={confirmModalState} isDisabled={deactivating}>Cancel</Button>
                                    <Button colorScheme='red' onClick={handleDeactivateUser} isDisabled={deactivating}>{deactivating ? <Spinner /> : "Deactivate"}</Button>
                                </ButtonGroup>
                            </PopoverFooter>
                        </PopoverContent>
                    </Popover>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>
    )
}

export default DeactivateUserModal