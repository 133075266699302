import React, { useState, useEffect } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    FormLabel,
    Input,
    Select,
    Text,
    Box,
    Tag,
    TagLabel,
    TagCloseButton,
    useToast,
    Spinner
} from '@chakra-ui/react'
import axios from 'axios'
import { generateRandomString } from '../../helpers/generate'
const access = [
    "KR",
    "SBC",
    "JB",
    "PB"
]
const NewUserModal = ({ modalOpen, modalClose, options, trigger }) => {
    const toast = useToast()
    const [brands, setBrands] = useState([])
    const [ranPassword, setRanPassword] = useState()
    const [newUserDetails, setNewUserDetail] = useState({
        username: "",
        email: "",
        password: ranPassword,
        role: "",
        access: brands,
        dept: "",
        fname: "",
        lname: "",
        storeName: ""
    })
    const [triger, setTrigger] = useState(false)
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    //listen for closing of modal sa that the brands will be restored to empty
    useEffect(() => {
        if (!modalOpen) {
            setBrands([]); // Clear brands array when modal is closed
            const newRandomPassword = generateRandomString();
            setRanPassword(newRandomPassword);
            setNewUserDetail({
                username: "",
                email: "",
                password: newRandomPassword,
                role: "",
                access: [],
                dept: "",
                fname: "",
                lname: ""
            });
        }
    }, [modalOpen]);
    //listen to changes of brands and update the access in the newUserDetails
    useEffect(() => {
        setNewUserDetail((prev) => ({
            ...prev,
            access: brands // Update access whenever brands changes
        }));
    }, [brands]);
    useEffect(() => {
        const fetchStores = async () => {
            setLoading(true)
            try {
                const resStores = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/maintenance/getAllStore`)
                console.log(resStores.data)
                setStores(resStores.data)
                setLoading(false)
            } catch (e) {
                setLoading(false)
                return toast({
                    title: 'Error',
                    position: "top-right",
                    description: "Failed to fetch stores.",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                })
            }
        }
        fetchStores()
    }, [])
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setNewUserDetail((prev) => ({
            ...prev,
            [name]: value.trim()
        }))
    }
    const handleSubmit = async () => {
        setIsSubmitting(true)
        if (!newUserDetails.username || !newUserDetails.email || !newUserDetails.role) {
            toast({
                title: 'Error',
                position: "top-right",
                description: "All fields are required.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            return setIsSubmitting(false)
        }
        let selectedAccess = newUserDetails.access
        if (newUserDetails.role === "admin") {
            selectedAccess = access
        }
        try {
            console.log(newUserDetails, selectedAccess)
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/createNewUserAdmin`, {
                ...newUserDetails, access: selectedAccess
            }, { withCredentials: true })
            setIsSubmitting(false)
            toast({
                title: 'Success',
                position: "top-right",
                description: "Successfully created new user.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            modalClose()
            trigger()

        } catch (err) {
            setIsSubmitting(false)
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    const addBrand = (brand) => {
        setBrands(prev => [...prev, brand])
    }
    const removeBrand = (brandToRemove) => {
        const updatedAcces = brands.filter(brand => brand !== brandToRemove)
        setBrands(updatedAcces)
        setTrigger(prev => !prev)
    }
    return (
        <Modal isOpen={modalOpen} onClose={modalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create new User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mt={4}>
                        <Text as={"b"}>First Name</Text>
                        <Input placeholder='First Name' value={newUserDetails.fName} onChange={handleInputChange} name="fname" />
                    </FormControl>
                    <FormControl my={4}>
                        <Text as={"b"}>Last Name</Text>
                        <Input placeholder='Last Name' value={newUserDetails.lname} onChange={handleInputChange} name="lname" />
                    </FormControl>
                    <FormControl>
                        <Text as={"b"}>Username</Text>
                        <Input placeholder='First name' value={newUserDetails.username} onChange={handleInputChange} name="username" />
                    </FormControl>
                    <FormControl mt={4}>
                        <Text as={"b"}>Email</Text>
                        <Input placeholder='Email' value={newUserDetails.email} onChange={handleInputChange} name="email" />
                    </FormControl>
                    <FormControl mt={4}>
                        <Text as={"b"}>Default Password</Text>
                        <Input isDisabled value={ranPassword} />
                        <Text fontSize='xs' style={{ color: 'gray', fontSize: 12 }} >Please remind the user to change the password once they login.</Text>
                    </FormControl>
                    <FormControl mt={4}>
                        <Text as={"b"}>Department</Text>
                        <Input placeholder='Department' value={newUserDetails.dept} onChange={handleInputChange} name="dept" />
                    </FormControl>
                    <FormControl mt={4}>
                        <Text as={"b"}>Role</Text>
                        <Select
                            variant="outline"
                            placeholder="Role"
                            value={newUserDetails.role}
                            onChange={handleInputChange}
                            name="role"
                        >
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>
                        {newUserDetails.role === "admin" && <Text mt={2} fontSize='xs' style={{ color: 'gray', fontSize: 12 }} >If the user is an admin, they will be granted access to all brands.</Text>}
                    </FormControl>
                    {newUserDetails.role === "store" && <FormControl mt={4}>
                        <Text as={"b"}>Store Name</Text>
                        <Select
                            variant="outline"
                            placeholder="Store Name"
                            value={newUserDetails.storeName}
                            onChange={handleInputChange}
                            name="storeName"
                        >

                            {stores.map((store) => (
                                <option key={store.StoreID} value={store.StoreName}>
                                    {store.
                                        StoreName
                                    }
                                </option>
                            ))}
                        </Select>
                        {newUserDetails.role === "admin" && <Text mt={2} fontSize='xs' style={{ color: 'gray', fontSize: 12 }} >If the user is an admin, they will be granted access to all brands.</Text>}
                    </FormControl>
                    }
                    {newUserDetails.role !== "admin" && <Box my={2}>
                        <Box mt={2}>
                            <Text as={"b"}>Access</Text>
                        </Box>
                        {brands.length === 0 ? "No Access selected." : brands.map((brand) => {
                            let colorScheme = "gray"
                            switch (brand) {
                                case 'KR':
                                    colorScheme = 'orange';
                                    break;
                                case 'PB':
                                    colorScheme = 'blue';
                                    break;
                                case 'JB':
                                    colorScheme = 'green';
                                    break;
                                case 'SBC':
                                    colorScheme = 'red';
                                    break;
                                default:
                                    colorScheme = 'gray';
                            }
                            return (
                                <Tag
                                    size={"lg"}
                                    key={brand}
                                    borderRadius='full'
                                    variant='solid'
                                    colorScheme={colorScheme}
                                    ml={2}
                                    mb={2}
                                    onClick={() => removeBrand(brand)}
                                >
                                    <TagLabel>{brand}</TagLabel>
                                    <TagCloseButton />
                                </Tag>
                            )
                        })}
                    </Box>}

                    {newUserDetails.role !== "admin" && brands.length < 4 && <Box>
                        <Text as={"b"}>Available Access</Text>
                        <Box mt={2}>
                            {access.filter(brand => !brands.includes(brand)).map((brand) => {
                                let colorScheme = "gray";
                                switch (brand) {
                                    case 'KR':
                                        colorScheme = 'orange';
                                        break;
                                    case 'PB':
                                        colorScheme = 'blue';
                                        break;
                                    case 'JB':
                                        colorScheme = 'green';
                                        break;
                                    case 'SBC':
                                        colorScheme = 'red';
                                        break;
                                    default:
                                        colorScheme = 'gray';
                                }
                                return (
                                    <Tag
                                        size={"lg"}
                                        key={brand}
                                        borderRadius='full'
                                        variant='solid'
                                        colorScheme={colorScheme}
                                        ml={2}
                                        onClick={() => addBrand(brand)}
                                        cursor={"pointer"}
                                        mb={2}
                                    >
                                        <TagLabel>{brand}</TagLabel>
                                    </Tag>
                                );
                            })}
                        </Box>
                    </Box>}
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' onClick={handleSubmit} isDisabled={isSubmitting}>{isSubmitting ? <Spinner /> : "Submit"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default NewUserModal