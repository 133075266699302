import React, { useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Box,
    Input,
    useToast
} from '@chakra-ui/react';
import axios from "axios"

const AddStoreModal = ({ modal, data, trigger }) => {
    const toast = useToast()
    const [inputData, setInputData] = useState({
        storeName: '',
        locCode: "",
        storecode: ""
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const handleInputChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value })
    }
    const handleSubmit = async () => {
        setIsSubmitting(true)
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/maintenance/addStore`, {
                brandId: data.brandId,
                storeName: inputData.storeName,
                locCode: inputData.locCode,
                storecode: inputData.storecode
            })
            setInputData(prev => {
                return { ...prev, storeName: '', locCode: "", storecode: "" }
            })
            modal.toggle()
            trigger()
            toast({
                title: 'Success',
                description: 'Store added successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            setIsSubmitting(false)
        } catch (e) {
            return toast({
                title: 'Error',
                description: 'Failed to add store',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }
    return (
        <Modal isOpen={modal.isOpen} onClose={modal.toggle}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add New Store</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box display={"flex"} flexDirection={"row"}>
                        <Text as={"b"}>Brand Name:</Text>
                        <Text as={"b"} color={data.color} ml={2}>{data.brandName}</Text>
                    </Box>
                    <Box mt={4}>
                        <Text>Store Name:</Text>
                        <Input name='storeName' value={inputData.storeName} onChange={(e) => handleInputChange(e)} />
                    </Box>
                    <Box mt={4}>
                        <Text>Loc Code:</Text>
                        <Input name='locCode' value={inputData.locCode} onChange={(e) => handleInputChange(e)} />
                    </Box>
                    <Box mt={4}>
                        <Text>Store Code:</Text>
                        <Input name='storecode' value={inputData.storecode} onChange={(e) => handleInputChange(e)} />
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={modal.toggle}>
                        Close
                    </Button>
                    <Button onClick={handleSubmit} colorScheme={data.color} isDisabled={inputData.locCode === "" || inputData.storeName === "" || isSubmitting}>
                        Create
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default AddStoreModal;