import React from 'react'
import { Flex, Box } from '@chakra-ui/react';

const AboutJB = ({ content }) => {
    return (
        <Box>
            {content}
        </Box>
    )
}

export default AboutJB