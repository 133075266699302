import React, { useState, useEffect, useRef } from 'react';
import Box from '@chakra-ui/react'
const { tableau } = window;
function TableauEmbed() {
    const ref = useRef(null);
    const url = "https://prod-apnortheast-a.online.tableau.com/t/ftsgroupbi/views/ParisBaguetteProductMix/TotalSalesMix";

    const initViz = () => {
            new tableau.Viz(ref.current, url, {
            token: window.localStorage.getItem('tableauToken'),
            width: "100%",
            height: "90vh",
        });
    };
    useEffect(initViz, []);

    return( 
            <div ref={ref} />
        );
    
    /* return (
        <div style={{ width: "100%", height: "90%", boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.2)", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <iframe src={"http://reports.ftsfood.com.ph:88/tableau/pb.html"} scrolling="no" height="100%" width="100%" title="Tableau Report"></iframe>
        </div>
    ); */
}

export default TableauEmbed;