import React, { useState, } from 'react'
import { Card, Box, Text, Button, Spinner } from "@chakra-ui/react"
import AddStoreModal from './AddStoreModal'
import DataTable from 'react-data-table-component'
import ConfirmModal from './ConfirmModal'

const CardsStore = ({ data, isLoading, trigger }) => {
    const [modalState, setModalState] = useState({
        addNewStoreModal: false,
        confirmModal: false
    })
    const [confrimModalData, setConfrimModalData] = useState({})
    const [mode, setMode] = useState("")
    const handleDelete = (data) => {
        setModalState(prev => {
            return { ...prev, confirmModal: true }
        })
        setConfrimModalData(data)
        setMode("delete")
    }
    const handleUpdate = (data) => {
        setModalState(prev => {
            return { ...prev, confirmModal: true }
        })
        setConfrimModalData(data)
    }
    const dataColumn = [
        {
            name: (
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Store Name</div>
            ),
            selector: (row) => row.StoreName,
            sortable: true,
            filterable: true,
        },
        {
            name: (
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Loc Code</div>
            ),
            selector: (row) => row.locCode,
            sortable: true,
            filterable: true,
        },
        {
            name: (
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Store Code</div>
            ),
            selector: (row) => row.storecode,
            sortable: true,
            filterable: true,
        },
        {
            name: (
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Action</div>
            ),
            selector: (row) => (
                <div>
                    <Button colorScheme='red' size={"sm"} onClick={() => handleDelete(row)}>
                        Delete
                    </Button>
                </div>
            ),
            sortable: true,
            filterable: true,
        },
        {
            name: (
                <div style={{ fontSize: 15, fontWeight: "bold" }}>Action</div>
            ),
            selector: (row) => (
                <div>
                    <Button colorScheme='blue' size={"sm"} onClick={() => handleUpdate(row)}>
                        Update
                    </Button>
                </div>
            ),
            sortable: true,
            filterable: true,
        },
    ]
    return (
        <Card m={6}>
            <Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Text fontSize={"2xl"} textAlign={"left"} ml={8} mt={4} fontWeight={"bold"}>{data.brandName}</Text>
                    <Button isDisabled={isLoading} colorScheme={data.color} m={5} onClick={() => {
                        setModalState(prev => {
                            return {
                                ...prev,
                                addNewStoreModal: true
                            }
                        })
                    }}>{isLoading ? <Spinner /> : "Add New Store"}</Button>
                    <AddStoreModal modal={{
                        isOpen: modalState.addNewStoreModal, toggle: () => {
                            setModalState(prev => {
                                return {
                                    addNewStoreModal: !prev.addNewStoreModal
                                }
                            })
                        }
                    }} data={{
                        brandName: data.brandName,
                        brandId: data.brandId,
                        color: data.color
                    }} trigger={trigger} />
                </Box>
                {isLoading ? <Spinner /> : <Box>
                    <DataTable
                        columns={dataColumn}
                        data={data.Stores}
                        pagination
                        searchable
                        highlightOnHover
                    />
                </Box>}
                <ConfirmModal mode={mode} modal={{
                    isOpen: modalState.confirmModal, toggle: () => setModalState(prev => {
                        return {
                            ...prev, confirmModal: !prev.confirmModal
                        }
                    })
                }} data={{
                    brandName: data.brandName,
                    color: data.color,
                    ...confrimModalData
                }} changeMode={() => setMode("")} trigger={trigger} />
            </Box>
        </Card>
    )
}

export default CardsStore