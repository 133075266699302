import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    Text,
    FormControl,
    useToast,
    Spinner

} from '@chakra-ui/react'
import { useState, useEffect } from 'react'
import axios from 'axios'

const ForgotPasswordModal = ({ isModalOpen, ModalClose }) => {
    const [userEmail, setUserEmail] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const toast = useToast()
    useEffect(() => {
        if (!isModalOpen) {
            setUserEmail("");
        }
    }, [isModalOpen]);
    const handleInputChange = (e) => {
        setUserEmail(e.target.value)
    }
    const handleSubmitPassword = async () => {
        setIsSubmitting(true)
        try {
            const resResetPassword = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/forgotPassword`, { email: userEmail })
            toast({
                title: 'Success',
                position: "top-right",
                description: resResetPassword.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setUserEmail("")
            setIsSubmitting(false)
            ModalClose()
        } catch (err) {
            setIsSubmitting(false)
            return toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
    }
    return (
        <>
            <Modal isOpen={isModalOpen} onClose={ModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Forgot Password</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Please Enter your email below, We will send you a temporary Password.</Text>
                        <FormControl mt={4}>
                            <Text as={"b"}>Email</Text>
                            <Input placeholder='Email' mt={2} value={userEmail} onChange={handleInputChange} name="fname" />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blue' variant='ghost' mr={3} isDisabled={isSubmitting} onClick={ModalClose}>
                            Close
                        </Button>
                        <Button colorScheme='blue' onClick={handleSubmitPassword} isDisabled={isSubmitting}>{isSubmitting ? <Spinner /> : "Submit"}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ForgotPasswordModal