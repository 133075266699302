import React, { useState, useEffect } from 'react'
import { Flex, Box, Select, Button, useToast, Spinner, Text } from '@chakra-ui/react';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { getFormatterDate } from '../../../helpers/date';
import AlohaPDFComponent from '../Files/AlohaPDFComponent';
import { keyframes } from "@emotion/react";
import {
    processAlohaHeaderReading, processAlohaSalesData, proccessAlohaDiscount, processAlohaGrossBreakDown,
    processAlohaCashierBreakDown, processAlohaPaymentData, processAlohaNonSalesData, processAlohaNonSalesDataWithTenderDesc,
    processAlohaBillNOQTY, processAlohaNonSales
} from '../../../helpers/eodReport';
import axios from 'axios';

const dateCurr = new Date();
const blink = keyframes`
0% {opacity: 1;}
50% {opacity: 0;}
100% {opacity: 1;}
`
const SalesSBC = ({ content, color }) => {
    const toast = useToast()
    const [reqField, setReqField] = useState({
        date: new Date(),
        branch: "",
    })
    const [stores, setStores] = useState([])
    const role = localStorage.getItem('role')
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState()
    const handleDateChange = (date) => {
        setReqField(prev => {
            return { ...prev, date: date }
        })
    }

    const handleBranchChange = (e) => {
        const selectedStore = stores.find(store => store.locCode === e.target.value);
        if (selectedStore) {
            setReqField(prev => ({
                ...prev,
                branch: selectedStore.StoreName,
                locCode: selectedStore.locCode,
                storecode: selectedStore.storecode
            }));
        }
    };
    const handleGenerateReport = async (date, branch) => {
        const newDate = getFormatterDate(date)
        const currDate = getFormatterDate(dateCurr);
        if (currDate < newDate) {
            toast({
                title: "Error",
                position: "top-right",
                description: "Date not Available.",
                status: "error",
                duration: 5000,
                isClosable: true
            });
            return;
        }
        if (reqField.locCode === "") {
            toast({
                title: "Error",
                position: "top-right",
                description: "Please select a store.",
                status: "error",
                duration: 5000,
                isClosable: true
            });
            return;
        }
        try {
            setDataLoading(true)
            // Helper function to check response type
            const isDataAvailable = (data) => Array.isArray(data) && data.length > 0;
            // Header
            const resHeader = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getHeaderReading.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resHeader?.data)) throw new Error('No header data found');
            const { totalGrossAmt, totalvat, openingData, closingData } = processAlohaHeaderReading(resHeader.data);
            // Sales Data
            const resSales = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getGrossSaleswithVat.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resSales?.data)) throw new Error('No sales data found');
            const { salesData } = processAlohaSalesData(resSales.data);
            // Discount Data
            const resDiscount = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getDiscount.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resDiscount?.data)) throw new Error('No discount data found');
            const { dataDiscount, dataDiscountVAT, dataDiscountVATE } = proccessAlohaDiscount(resDiscount.data);

            // Gross Breakdown
            const resGrossBreakDown = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getSaleswPerChannelithVat.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resGrossBreakDown?.data)) throw new Error("No Gross Breakdown data found.");
            const { dataGrossBreakDownReturned, totalGrossBreakDownVAT, totalGrossBreakDownVATE } = processAlohaGrossBreakDown(resGrossBreakDown.data);

            // Payment Breakdown
            const resPaymentData = await axios.post(`${process.env.REACT_APP_ALOHA_API_URL}/getPaymentData.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resPaymentData?.data)) throw new Error("No payment breakdown data found.");
            const { totalPaymentCash, totalPaymentNonCash, paymentDataReturned, ChangeAmount, TenderAmount, VoidPaymentAmount, VoidPaymentCount, totalNonCashQty, NonSalesPaymentDataReturned, totalNonSalesCount } = processAlohaPaymentData(resPaymentData.data);

            // Cashier Breakdown
            const resCashierBreakDown = await axios.post(`${process.env.REACT_APP_ALOHA_API_URL}/getCashAccountability.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resCashierBreakDown?.data)) throw new Error("No Cashier Breakdown data found.");
            const { cashierBreakDownReturned, totalCashierAmount, totalShortOver, shortOverDataReturned, totalShortOverNonCash, shortOverNonCashDataReturned, CMCash, CMFloat, CMOverShort, CMPickUp } = processAlohaCashierBreakDown(resCashierBreakDown.data);

            // BillNoQTY
            const resBillQtyNo = await axios.post(`${process.env.REACT_APP_ALOHA_API_URL}/getBillNoAndQty.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resBillQtyNo?.data)) throw new Error("No BillNoQTY data found.");
            const { TCQty, OrderQty } = processAlohaBillNOQTY(resBillQtyNo.data);

            // Non Misc
            const resMisc = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getMisc.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resMisc?.data)) throw new Error("No Non Sales data found.");
            const { NonSalesDataReturned } = processAlohaNonSalesData(resMisc.data);

            // Non Cash with tenderDesk
            const resNonCashTenderDesk = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getNonCash.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`);
            if (!isDataAvailable(resNonCashTenderDesk?.data)) throw new Error("No misc data found.");
            const { NonSalesWithTenderDesc, totalNonSales, totalNonSalesCounter } = processAlohaNonSalesDataWithTenderDesc(resNonCashTenderDesk.data);
            console.log({ NonSalesWithTenderDesc, totalNonSales, totalNonSalesCounter })
            //Non Sales
            const resNonSales = await axios.get(`${process.env.REACT_APP_ALOHA_API_URL}/getNonsales.aspx?brand=SBC&qlik=${reqField.locCode}&datedfolder=${newDate.replace(/-/g, "")}`)
            if (!isDataAvailable(resNonSales?.data)) throw new Error("No Non Cash with tenderDesk data found.");
            const { nonSalesData } = processAlohaNonSales(resNonSales.data)
            // Setting data after successful API responses
            setData({
                nonSalesData,
                currDate: currDate,
                BizDate: newDate,
                NonSalesWithTenderDesc,
                totalNonSales,
                totalNonSalesCounter,
                NonSalesDataReturned,
                cashierBreakDownReturned,
                totalCashierAmount,
                totalShortOver,
                shortOverDataReturned,
                totalShortOverNonCash,
                shortOverNonCashDataReturned,
                CMCash,
                CMFloat,
                CMOverShort,
                CMPickUp,
                totalPaymentCash,
                totalPaymentNonCash,
                paymentDataReturned,
                ChangeAmount,
                TenderAmount,
                VoidPaymentAmount,
                VoidPaymentCount,
                totalNonCashQty,
                NonSalesPaymentDataReturned,
                totalNonSalesCount,
                dataDiscount,
                dataDiscountVAT,
                dataDiscountVATE,
                salesData,
                totalGrossAmt,
                totalvat,
                openingData,
                closingData,
                dataGrossBreakDownReturned,
                totalGrossBreakDownVAT,
                totalGrossBreakDownVATE,
                PosId: reqField.locCode,
                TCQty,
                OrderQty,
                storeName: reqField.branch,
                storeCode: reqField.storecode
            });
        } catch (e) {
            toast({
                title: "Error",
                position: "top-right",
                description: e.message,
                status: "error",
                duration: 5000,
                isClosable: true
            });
        } finally {
            setDataLoading(false)
        }
    }
    useEffect(() => {
        const fetchStores = async () => {
            try {
                const resstores = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/maintenance/getBrands`, {
                    brandId: 2
                })
                setStores(resstores.data)
                console.log(resstores.data)
                if (role === "store") {
                    const store = resstores.data.find(store => store.storeName === localStorage.getItem("storeName"))
                    setReqField(prev => ({ ...prev, branch: store.StoreName, locCode: store.locCode }))
                }
            } catch (e) {
                toast({
                    title: "Error",
                    position: "top-right",
                    description: "Failed to fetch stores.",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                })
            }
        }
        fetchStores()
    }, [])

    const [file, setFile] = useState()

    function handleChange(event) {
        setFile(event.target.files[0])
    }
    
        function  handleSubmit(event) {
            event.preventDefault()
            const url = 'https://reports.ftsfood.com.ph/api/dfmupload';
            const formData = new FormData();
            formData.append('destination', reqField.locCode);
            formData.append('file', file);
            formData.append('fileName', file.name);
            
            const config = {
            headers: {
                'content-type': 'multipart/form-data',
                },
            };
            axios.post(url, formData, config).then((response) => {
            //console.log(reqField.locCode);

                //Save
                const fn = response.data;
                const loccode = reqField.locCode;
                //console.log(fn.success);
                //if (response.data.includes(".rar")){
                    try {
                        const uploadresponse = axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/dfmdetails`, {
                            foldername: loccode,
                            filename: fn.success,
                            dob: reqField.date
                        })
                    } catch (err) {
                        alert(err);          
                    }
                    //alert("The file has been successfully uploaded. Please return in a few minutes.");
                    toast({
                        title: "Success",
                        position: "top-right",
                        description: "The file has been successfully uploaded. Please return in a few minutes.",
                        status: "success",
                        duration: 5000,
                        isClosable: true
                    });
                //}else{
                    //alert(response.data);
                //}

            });
        }

    return (
        <Box>
            <Box my={6} display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexDirection={{ base: "column", lg: "row" }}>
                <Box border="2px" borderColor="black" borderRadius={"2px"} display={"flex"} justifyContent={"center"} alignItems={"center"} mb={{ base: 5, lg: 0 }}>
                    <DatePicker name='date' disabled={dataLoading} maxDate={new Date()} selected={reqField.date} onChange={(date) => handleDateChange(date)} className='custom-date-picker' customInput={<div style={{ position: 'relative' }}>
                        <input
                            className="custom-date-picker-input"
                            value={reqField.date.toLocaleDateString()}
                            readOnly
                        />
                        <img
                            src="/calendar.svg"
                            className="calendar-icon"
                            alt="Calendar Icon"
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '20px',
                                height: 'auto'
                            }}
                        />
                    </div>} />
                </Box>
                <Box mb={{ base: 4, lg: 0 }}>
                    <Select placeholder='Branch' name='branch' onChange={handleBranchChange} defaultValue={"PBMOA1,PBMOAK1"} disabled={dataLoading}>
                        {stores.map((store) => {
                            return <option key={store.StoreID} value={store.locCode}>{store.StoreName}</option>
                        })}
                    </Select>
                </Box>
                <Box mb={{ base: 4, lg: 0 }}>
                    <Button colorScheme={color} size={{ base: "sm", md: "md" }} onClick={() => handleGenerateReport(reqField.date, reqField.branch)} isDisabled={dataLoading}>Generate Report</Button>
                </Box>
                <Box>
                    <Button colorScheme={color} size={{ base: "sm", md: "md" }} >
                        {data ? (
                            <PDFDownloadLink document={<AlohaPDFComponent data={data} />} fileName={`EODReport-${reqField.branch}-${getFormatterDate(reqField.date)}.pdf`} >
                                {({ loading }) => (loading ? 'Loading document...' : 'Download Sales Report')}
                            </PDFDownloadLink>
                        ) : //Show only when no response
                            
                        <form name={reqField.locCode} onSubmit={handleSubmit}>
                            <input type="file" accept=".rar" onChange={handleChange} />                    
                            <Button type="submit">Upload</Button>
                        </form>
                        }
                    </Button>
                </Box>
            </Box>
            <Box my={8} height="80vh">
                {dataLoading ? <Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}><Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                /> <Text css={{ animation: `${blink} 1s linear infinite` }} marginLeft={4}>Fetching Data.....</Text></Box> : <PDFViewer showToolbar={false} style={{ width: '100%', height: '100%' }}>
                    <AlohaPDFComponent data={data} />
                </PDFViewer>}
            </Box >
        </Box>
    )
}

export default SalesSBC