import React from 'react'
import NavBar from '../Components/Navbar/NavBar'
import { Card, CardHeader, CardBody, CardFooter, Text, Box, Image } from '@chakra-ui/react'

const DeactivatedUserPage = () => {
    return (
        <>
            <NavBar />
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} width={"100%"} height="calc(100vh - 80px)">
                <Box bg={"gray.300"}
                    borderRadius='xl' padding={{ base: "20px", md: "70px" }}>
                    <Box alignItems={"center"} display={"flex"} justifyContent={"center"} mb={5}>
                        <Image
                            boxSize='200px'
                            objectFit='cover'
                            src="/DeactivatedUserImg.png"
                            alt='Deactivated User'

                        />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Text as={"b"} fontSize='3xl' mb={4}>Deactivated</Text>
                        <Text >Your account is Deactivated. Please contact the admin.</Text>
                    </Box>
                </Box>
            </Box >
        </>
    )
}

export default DeactivatedUserPage