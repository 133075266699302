import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Text,
    Input,
    useToast,
    Spinner
} from '@chakra-ui/react'
import axios from 'axios'

const NavbarChangePassword = ({ modlaState, modalClose, idLogin }) => {
    const toast = useToast()
    const [password, setPassword] = useState({
        oldPass: "",
        newPass: "",
        cNewPass: ""
    })
    const [submitting, setSubmitting] = useState(false)
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setPassword((prev) => ({
            ...prev,
            [name]: value
        }))
    }
    const handleSubmit = async () => {
        setSubmitting(true)
        if (!password.oldPass || !password.newPass || !password.cNewPass) {
            setSubmitting(false)
            return toast({
                title: 'Error',
                position: "top-right",
                description: "All fields are required.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        if (password.newPass !== password.cNewPass) {
            setSubmitting(false)
            return toast({
                title: 'Error',
                position: "top-right",
                description: "New Password and Confirm Password does not match",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/userChangePassword`, {
                id: idLogin,
                password: password.newPass,
                cPassword: password.cNewPass,
                oldPassword: password.oldPass
            }, { withCredentials: true })
            toast({
                title: 'Success',
                position: "top-right",
                description: res.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setSubmitting(false)
            setPassword({
                oldPass: "",
                newPass: "",
                cNewPass: ""
            })
            modalClose()

        } catch (err) {
            setSubmitting(false)
            return toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })

        }

    }
    return (
        <Modal isOpen={modlaState} onClose={modalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Change Password</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mt={4}>
                        <Text as={"b"}>Old Password</Text>
                        <Input type="password" placeholder='Old Password' value={password.oldPass} onChange={handleInputChange} name="oldPass" />
                    </FormControl>
                    <FormControl my={4}>
                        <Text as={"b"}>New Password</Text>
                        <Input type="password" placeholder='New Password' value={password.newPass} onChange={handleInputChange} name="newPass" />
                    </FormControl>
                    <FormControl>
                        <Text as={"b"}>Confirm New Password</Text>
                        <Input type="password" placeholder='Re-Enter New Password' value={password.cNewPass} onChange={handleInputChange} name="cNewPass" />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='blue' mr={3} isDisabled={submitting} onClick={handleSubmit}>
                        {submitting ? <Spinner /> : "Submit"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default NavbarChangePassword