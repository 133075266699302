import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  InputRightElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Link,
  useToast,
  Spinner
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import Cookies from 'js-cookie';
import ForgotPasswordModal from "./ForgotPasswordModal";
import Logo from "../../Assets/Logo.png"
import Logo2 from "../../Assets/Logo2.png"


const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const CFaEye = chakra(FaEye);
const CFaEyeSlash = chakra(FaEyeSlash);

const Login = ({ onFormSwitch }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLogginIn, setIsLogginIn] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isForgotPasswordModal, setisForgotPasswordModal] = useState(false);
  const handleforgotPassModalState = () => {
    setisForgotPasswordModal(!isForgotPasswordModal)
  }

  const handleVisibilityPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  }
  const handlePasswordFocus = () => {
    setShowPassword(true);
  };

  const handlePasswordBlur = () => {
    setShowPassword(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setShowPassword(false);
  };

  const handleLoginFunction = async (e) => {
    e.preventDefault();
    setIsLogginIn(true);
    if (!email || !password) {
      alert("Please enter both email and password");
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/login`, {
        email: email,
        password: password
      }, { withCredentials: true });
      if (response?.data.accessToken) {
        //alert("Login Successful!");
        Cookies.set('accessToken', response?.data.accessToken, { expires: 7 });
        localStorage.setItem('email', response?.data.email); // Store email in localStorage
        localStorage.setItem("role", response?.data.role)
        localStorage.setItem("id", response?.data.id)
        localStorage.setItem("storeName", response?.data.storeName)
        setIsLogginIn(false);
        navigate('/homepage');
        try {
          const resJWTTableau = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/generatejwt`,
            { withCredentials: true })
          localStorage.setItem("tableauToken", resJWTTableau.data)
        } catch (err) {
          toast({
            title: 'Invalid',
            colorScheme: 'red',
            description: err.message,
            duration: 2000,
            isClosable: true,
            status: 'error',
            position: 'top',

          })
        }
      } else {
        setIsLogginIn(false);
        toast({
          title: 'Invalid',
          colorScheme: 'red',
          description: 'Invalid Credentials',
          duration: 2000,
          isClosable: true,
          status: 'error',
          position: 'top',

        });
      }
    } catch (error) {
      setIsLogginIn(false);
      toast({
        title: 'Error',
        colorScheme: 'red',
        description: error.response.data.message,
        duration: 2000,
        isClosable: true,
        status: 'error',
        position: 'top',

      });
    }
  };


  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <img src={Logo2} alt="Logo" width="65px" />
        <Heading color="blue.400">Login</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleLoginFunction}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              borderWidth="1px"
              borderColor="gray.400"
              borderRadius="md"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    _placeholder={{ color: 'gray.500' }}
                    color="black"
                    borderColor="gray.300"
                    borderWidth="1px"
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={passwordVisibility ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    _placeholder={{ color: 'gray.500' }}
                    color="black"
                    borderColor="gray.300"
                    borderWidth="1px"
                    onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}
                  />
                  <InputRightElement display="flex" alignItems="stretch">
                    {passwordVisibility ? (
                      <Button onClick={handleVisibilityPassword} w={"100%"} m={0} p={0}>
                        <CFaEyeSlash
                          h="50%"
                          w="50%"
                          size="sm"
                          cursor="pointer"
                          color="gray"
                        />
                      </Button>
                    ) : (
                      <Button onClick={handleVisibilityPassword} w={"100%"} m={0} p={0}>
                        <CFaEye
                          h="50%"
                          w="50%"
                          size="sm"
                          cursor="pointer"
                          color="gray"
                        />
                      </Button>
                    )}
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="blue"
                width="full"
                isDisabled={isLogginIn}
              >
                {isLogginIn ? <Spinner size="sm" /> : 'Login'}
              </Button>
              <Button
                color="blue.400"
                alignSelf="flex-start"
                fontSize="sm"
                variant="link"
                onClick={handleforgotPassModalState}
              >
                Forgot Password?
              </Button>
              <ForgotPasswordModal isModalOpen={isForgotPasswordModal} ModalClose={handleforgotPassModalState} />
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Login;
