import React, { useEffect, useState } from 'react'
import { Flex, Box, Button, useToast, Text, Select, Spinner } from '@chakra-ui/react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { keyframes } from "@emotion/react";
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { PDFViewer } from '@react-pdf/renderer'
import PDFComponent from '../Files/PDFComponent';
import axios from 'axios';
import { getFormatterDate } from '../../../helpers/date';
import { processGrossBreakDown, processHeaderData, processPaymentData, processSalesData, processdiscounts, processCashierBreakDown, processBillNoAndQty, processNonSalesData, processNonSalesDataWithTenderDesc } from "../../../helpers/eodReport"

const currentTime = new Date()
const BRANCH_PB = [
    { name: "Mall of Asia + Kiosk", value: "PBMOA1,PBMOAK1" },
    { name: "Mall of Asia", value: "PBMOA1" },
    { name: "Mall of Asia kiosk", value: "PBMOAK1" },
    { name: "SM North + Kiosk", value: "PBSMNE1,PBSMNEK1" },
    { name: "SM North", value: "PBSMNE1" },
    { name: "SM North Kiosk", value: "PBSMNEK1" }
]

const SalesPB = ({ content, color }) => {
    const blink = keyframes`
    0% {opacity: 1;}
    50% {opacity: 0;}
    100% {opacity: 1;}
    `
    const toast = useToast()
    const location = useLocation()
    const brand = location.pathname.substring(1)
    const [isPDFViewerVisible, setIsPDFViewerVisible] = useState(false)
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [data, setData] = useState()
    const [noDataErr, setNoDataErr] = useState(false)
    const [selectedBranch, setSelectedBranch] = useState("PBMOA1,PBMOAK1");
    const [dataLoading, setDataLoading] = useState(false)

    const handleBranchChange = (e) => {
        const new_branch = e.target.value
        setSelectedBranch(new_branch)
    }

    const handleGenerateReport = async (date, branch) => {
        setDataLoading(true)
        const selected = getFormatterDate(date)
        const curr_date = getFormatterDate(currentTime)

        try {
            const resDataHeader = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getHeaderReading`, {
                branch: branch,
                date: selected
            }, { withCredentials: true })

            if (!resDataHeader?.data) throw new Error('No header data found');
            const { openingData, closingData, totalGrossAmt, totalvat } = processHeaderData(resDataHeader.data)

            const resSalesData = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getSalesData`, {
                branch: branch,
                date: selected
            }, { withCredentials: true })

            if (!resSalesData?.data) throw new Error('No sales data found');
            const { salesData } = processSalesData(resSalesData.data)

            const resDiscountData = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getDiscountData`, {
                branch: branch,
                date: selected
            }, { withCredentials: true })

            if (!resDiscountData?.data) throw new Error('No discount data found');
            const { dataDiscount, dataDiscountVAT, dataDiscountVATE } = processdiscounts(resDiscountData.data)

            const resGrossBreakDown = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getGrossBreakDown`, {
                branch: branch,
                date: selected
            }, { withCredentials: true })

            if (!resGrossBreakDown?.data) throw new Error('No gross breakdown data found');
            const { dataGrossBreakDownReturned, totalGrossBreakDownVAT, totalGrossBreakDownVATE } = processGrossBreakDown(resGrossBreakDown.data)

            const resPaymentData = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getPaymentData`, { branch, date: selected }, { withCredentials: true })

            if (!resPaymentData?.data) throw new Error('No payment data found');
            const { totalPaymentNonCash, paymentDataReturned, totalNonSalesCount, ChangeAmount, TenderAmount, VoidPaymentAmount, VoidPaymentCount, NonSalesPaymentDataReturned, totalNonCashQty } = processPaymentData(resPaymentData.data)

            const resCashierBreakDown = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getCashierBreakDown`, { branch, date: selected }, { withCredentials: true })

            if (!resCashierBreakDown?.data) throw new Error('No cashier breakdown data found');
            const { cashierBreakDownReturned, totalCashierAmount, totalShortOver, shortOverDataReturned, totalShortOverNonCash, shortOverNonCashDataReturned, CMCash, CMFloat, CMOverShort, CMPickUp } = processCashierBreakDown(resCashierBreakDown.data)

            const resBillNoQty = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getBillNoAndQty`, { branch, date: selected }, { withCredentials: true })
            if (!resBillNoQty?.data) throw new Error('No bill number and quantity data found');
            const { TCQty, OrderQty } = processBillNoAndQty(resBillNoQty.data)

            const resNonSalesData = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getNonSalesData`, { branch, date: selected }, { withCredentials: true })

            if (!resNonSalesData?.data) throw new Error('No non-sales data found');
            const { NonSalesDataReturned } = processNonSalesData(resNonSalesData.data)

            const resGetNonSalesDataWithTenderDesc = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/getNonSalesDataWithTenderDesc`, { branch, date: selected }, { withCredentials: true })
            if (!resGetNonSalesDataWithTenderDesc?.data) throw new Error('No non-sales data with tender description found');
            const { NonSalesWithTenderDesc, totalNonSales, totalNonSalesCounter } = processNonSalesDataWithTenderDesc(resGetNonSalesDataWithTenderDesc.data)
            const {costCenter} = '-'
            setData({
                dataDiscountVAT,
                dataDiscountVATE,
                BizDate: selected,
                totalNonSalesCounter,
                NonSalesPaymentDataReturned,
                totalShortOverNonCash,
                shortOverNonCashDataReturned,
                currDate: curr_date,
                PosId: branch,
                openingData,
                closingData,
                totalGrossAmt,
                salesData,
                totalvat,
                dataDiscount,
                dataGrossBreakDownReturned,
                totalCashierAmount,
                totalPaymentNonCash,
                paymentDataReturned,
                ChangeAmount,
                TenderAmount,
                totalGrossBreakDownVAT,
                totalGrossBreakDownVATE,
                cashierBreakDownReturned,
                VoidPaymentAmount,
                VoidPaymentCount,
                TCQty,
                OrderQty,
                NonSalesDataReturned,
                shortOverDataReturned,
                totalShortOver,
                totalNonCashQty,
                CMCash,
                CMFloat,
                CMOverShort,
                CMPickUp,
                NonSalesWithTenderDesc,
                totalNonSales,
                totalNonSalesCount,
                costCenter
            })
        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        } finally {
            setDataLoading(false)
        }
    }

    const handleDateChange = async (date) => {
        setSelectedDate(date)
    }

    return (
        <>
            <Box my={10} display={"flex"} justifyContent={"space-between"} alignItems={"center"} flexDirection={{ base: "column", lg: "row" }}>
                <Box border="2px" borderColor="black" borderRadius={"2px"} display={"flex"} justifyContent={"center"} alignItems={"center"} mb={{ base: 5, lg: 0 }}>
                    <DatePicker disabled={dataLoading} maxDate={new Date()} selected={selectedDate} onChange={(date) => handleDateChange(date)} className='custom-date-picker' customInput={<div style={{ position: 'relative' }}>
                        <input
                            className="custom-date-picker-input"
                            value={selectedDate.toLocaleDateString()}
                            readOnly
                        />
                        <img
                            src="/calendar.svg"
                            className="calendar-icon"
                            alt="Calendar Icon"
                            style={{
                                position: 'absolute',
                                right: '20px',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                width: '20px',
                                height: 'auto'
                            }}
                        />
                    </div>} />
                </Box>
                <Box mb={{ base: 4, lg: 0 }}>
                    <Select placeholder='Branch' onChange={handleBranchChange} defaultValue={"PBMOA1,PBMOAK1"} disabled={dataLoading}>
                        {BRANCH_PB.map((branch, index) => (
                            <option key={index} value={branch.value}>{branch.name}</option>
                        ))}
                    </Select>
                </Box>
                <Box mb={{ base: 4, lg: 0 }}>
                    <Button colorScheme={color} size={{ base: "sm", md: "md" }} onClick={() => handleGenerateReport(selectedDate, selectedBranch)} isDisabled={dataLoading}>Generate Report</Button>
                </Box>
                <Box mb={{ base: 4, lg: 0 }}>
                    <Button colorScheme={color} size={{ base: "sm", md: "md" }} isDisabled={!data || dataLoading}>
                        {data ? (
                            <PDFDownloadLink document={<PDFComponent data={data} />} fileName={`EODReport-${selectedBranch}-${getFormatterDate(selectedDate)}.pdf`} >
                                {({ loading }) => (loading ? 'Loading document...' : 'Download Sales Report')}
                            </PDFDownloadLink>
                        ) : "No data Found."}
                    </Button>
                </Box>
            </Box >
            <Box my={8} height="80vh">
                {dataLoading ? <Box height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}><Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                /> <Text css={{ animation: `${blink} 1s linear infinite` }} marginLeft={4}>Fetching Data.....</Text></Box> : <PDFViewer showToolbar={false} style={{ width: '100%', height: '100%' }}>
                    <PDFComponent data={data} />
                </PDFViewer>}
            </Box >
        </>
    )
}

export default SalesPB