import React, { Suspense, useEffect, useState } from 'react'
import NavBar from "../../Components/Navbar/NavBar"
import { Grid, Flex, Box, Text, Button, Card, Input, Select, Skeleton, Spinner, useToast } from "@chakra-ui/react"
import DataTable from "react-data-table-component"
import axios from "axios"
import UpdateAccessModal from './UpdateAccessModal'
import NewUserModal from './NewUserModal'
import DeactivateUserModal from './DeactivateUserModal'
import ResetPasswordModal from './ResetPasswordModal'
const options = [
    { value: "admin", label: "Admin" },
    { value: "analyst", label: "Analyst" },
    { value: "store", label: "Store" },
    { value: "excom", label: "ExCom", },
]

const UserManagement = ({ currentUserId }) => {
    const toast = useToast()
    const [users, setUsers] = useState([])
    const [filteredUser, setFiltereUser] = useState()
    const [pageLoading, setPageLoading] = useState(false)
    const [selectedUser, setSelectedUser] = useState({
        selectedUser: "",
        selectedUserId: "",
        selectedUserAcces: []
    })
    const [newUserModal, setNewUserModal] = useState(false)
    const [removeUserModal, setRemoveUserModal] = useState(false)
    const [trigger, setTrigger] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isSubmittingRole, setisSubmittingRole] = useState(false)
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            setPageLoading(true)
            const res = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/getAlluser`, { withCredentials: true })
            setUsers(res.data)
            setPageLoading(false)
        }
        fetchData()
    }, [trigger])
    const handleSearchUser = (e) => {
        const searchVal = e.target.value.toLowerCase()
        const filteredData = users.filter(user => user.username?.toLowerCase().includes(searchVal)
            || user.role?.toLowerCase().includes(searchVal))
        setFiltereUser(filteredData)
    }
    const handleUpdateAccess = (row) => {
        setSelectedUser({
            selectedUser: row.username,
            selectedUserId: row.idlogin,
            selectedUserAcces: row.access
        })
    }
    const modalState = () => {
        setTrigger(prev => !prev)
        setModalOpen(prev => !prev)
    }
    const handleUpdateRole = (val, id) => {
        setisSubmittingRole(true)
        try {
            axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/updateUserRole`, {
                id: id,
                role: val
            }, { withCredentials: true })
            toast({
                title: 'Success',
                position: "top-right",
                description: "You successfully updated the user Role.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setisSubmittingRole(false)
            setTrigger(prev => !prev)

        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setisSubmittingRole(false)
        }
    }
    const newUserModalOpen = () => {
        setNewUserModal(true)
    }
    const newUserModalClose = () => {
        setTrigger(prev => !prev)
        setNewUserModal(false)
    }
    const removeModalState = () => {
        setRemoveUserModal(prev => !prev)
    }
    const resetPasswordModalState = () => {
        setResetPasswordModal(prev => !prev)
    }
    return (
        <Box height={"100vh"}>
            <NavBar inManagement />
            <Card mx={6} mt={6}>
                <Box mx={6}>
                    <Box mt={5} display={"flex"} flexDirection={{ base: "column", md: "row" }} justifyContent={"space-between"} mb={7}>

                        <Text fontSize={"3xl"} fontWeight={"bold"} >User Management</Text>
                        <Box display={"flex"} flexDirection={{ base: "column", md: "row" }} justifyContent={"space-evenly"} mt={{ base: 5, md: 0 }} >
                            <Button onClick={newUserModalOpen} bg={"green"} textColor={"white"} isDisabled={pageLoading} mb={{ base: 2, md: 0 }} mr={{ base: 2, md: 5 }} >{pageLoading ? <Spinner /> : "New User"}</Button>
                            <Button onClick={removeModalState} bg={"tomato"} textColor={"white"} isDisabled={pageLoading} mb={{ base: 2, md: 0 }} mr={{ base: 2, md: 5 }}>{pageLoading ? <Spinner /> : "Deactivate User"}</Button>
                            <Button onClick={resetPasswordModalState} bg={"blue"} textColor={"white"} isDisabled={pageLoading}>{pageLoading ? <Spinner /> : "Reset Password"}</Button>
                        </Box>
                    </Box>
                    <Input placeholder='Search User' onChange={handleSearchUser} mb={5} isDisabled={pageLoading} />
                    <Skeleton isLoaded={!pageLoading}>
                        <DataTable
                            columns={[
                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>ID</div>
                                    ),
                                    selector: (row) => row.idlogin,
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>Name</div>
                                    ),
                                    selector: (row) => row.username,
                                    sortable: true,
                                    filterable: true,
                                },

                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>Role</div>
                                    ),
                                    selector: (row) => row.role,
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>Department</div>
                                    ),
                                    selector: (row) => row.department,
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>Access</div>
                                    ),
                                    selector: (row) => (<Text
                                        textDecoration="underline"
                                        color="blue.500"
                                        cursor="pointer"
                                        onClick={() => {
                                            handleUpdateAccess(row)
                                            modalState()
                                        }}
                                    >
                                        {row.access.length > 0 ? row.access.join(', ') : "add access"}
                                    </Text>),
                                    sortable: true,
                                    filterable: true,
                                },

                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>Ownership</div>
                                    ),
                                    selector: (row) => row.ownership,
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    name: (
                                        <div style={{ fontSize: 15, fontWeight: "bold" }}>
                                            Change Role
                                        </div>
                                    ),
                                    cell: (row) => (
                                        <>
                                            <Select
                                                variant="outline"
                                                placeholder="Role"
                                                value="option1"
                                                isDisabled={currentUserId === row.idlogin || isSubmittingRole}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    const id = row.idlogin;
                                                    handleUpdateRole(selectedValue, id);
                                                }}
                                            >
                                                {options
                                                    .filter((option) => option.value !== row.role)
                                                    .map((option) => (
                                                        <option key={option.value} value={option.value}>
                                                            {isSubmittingRole ? "Loading..." : option.label}
                                                        </option>
                                                    ))}
                                            </Select>
                                        </>
                                    ),
                                },
                            ]}
                            data={filteredUser ? filteredUser : users}
                            pagination
                            searchable
                            //  customFilterText={customFilterText}
                            highlightOnHover
                        />
                    </Skeleton>
                </Box>
                <UpdateAccessModal modalOpen={modalOpen} modalClose={modalState} user={selectedUser} />
                <NewUserModal modalOpen={newUserModal} modalClose={newUserModalClose} options={options} trigger={() => setTrigger(prev => !prev)} />
                <DeactivateUserModal modalOpen={removeUserModal} modalClose={removeModalState} trigger={() => setTrigger(prev => !prev)} />
                <ResetPasswordModal modalOpen={resetPasswordModal} modalClose={resetPasswordModalState} />

            </Card>
        </Box>
    )
}

export default UserManagement