import React, { useState, useEffect } from 'react';
import { Box, Button, Spinner, useMediaQuery } from '@chakra-ui/react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, useToast } from '@chakra-ui/react';
import { RepeatIcon } from "@chakra-ui/icons"
import TableauEmbed from '../../../Components/TableauEmbed';

import TableauEmbedPBChannel from '../../../Components/EmbedPBChannel/EmbedPBChannel';
import TableauEmbedPBDSR from '../../../Components/EmbedPBDSR/EmbedPBDSR';
import TableauEmbedPBHourly from '../../../Components/EmbedPBHourly/EmbedPBHourly';
import TableauEmbedPBPMIX from '../../../Components/EmbedPBPMIX/EmbedPBPMIX';
import TableauEmbedPBTender from '../../../Components/EmbedPBTender/EmbedPBTender';
import TableauEmbedPBWeekly from '../../../Components/EmbedPBWeekly/EmbedPBWeekly';
import TableauEmbedPBNPS from '../../../Components/EmbedPBNPS/EmbedPBNPS';
import TableauEmbedPlay from '../../../Components/EmbedPBPlay/EmbedPBPlay';

//MObile Tableau Embeds
import TableauEmbedPBChannelMobile from '../../../Components/EmbedPBChannel/EmbedPBChannelMobile';
import TableauEmbedPBDSRMobile from '../../../Components/EmbedPBDSR/EmbedPBDSRMobile';
import TableauEmbedPBHourlyMobile from '../../../Components/EmbedPBHourly/EmbedPBHourlyMobile';
import TableauEmbedPBPMIXMobile from '../../../Components/EmbedPBPMIX/EmbedPBPMixMobile';
import TableauEmbedPBTenderMobile from '../../../Components/EmbedPBTender/EmbedPBTenderMobile';
import TableauEmbedPBWeeklyMobile from '../../../Components/EmbedPBWeekly/EmbedPBWeeklyMobile';
import TableauEmbedPBNPSMobile from '../../../Components/EmbedPBNPS/EmbedPBNPSMobile';

import axios from 'axios';
 
const Reports = ({ content }) => {
        const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");
        const [refreshPage, setRefreshPage] = useState(false)
        const [loading, setLoading] = useState(false)
        const [firstLoad, setFirstLoad] = useState(true)
        const [key, setKey] = useState(Date.now());
        const toast = useToast();
        const handleRefreshTokenTableauButton = async () => {
                try {
                        setLoading(true)
                        const resJWTTableau = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/generatejwt`,
                                { withCredentials: true })
                        localStorage.setItem("tableauToken", resJWTTableau.data)
                        setRefreshPage(prev => !prev)
                        setKey(Date.now())
                        setLoading(false)

                } catch (err) {
                        setLoading(false)
                        toast({
                                title: 'Error',
                                position: "top-right",
                                description: err.response.data.message,
                                status: 'error',
                                duration: 9000,
                                isClosable: true,
                        })
                }
        }
        useEffect(() => {
                const timer = setTimeout(() => {
                        setFirstLoad(false);
                }, 1000 * 3); // 10 seconds

                return () => clearTimeout(timer);
        }, [refreshPage])
        return (
                <>
                        <div key={key}>
                                <Box mt={4} mb={2}>
                                        <Button colorScheme="blue" onClick={handleRefreshTokenTableauButton} isDisabled={loading}>{loading ? <Spinner /> : <>Refresh <RepeatIcon marginLeft={2} /></>}</Button>
                                </Box>
                                <Tabs isFitted variant='enclosed'>
                                        <TabList mb='1em' style={{
                                                overflowX: 'auto',
                                                whiteSpace: 'nowrap',
                                                scrollbarWidth: 'none',
                                                msOverflowStyle: 'none',
                                        }}>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Product Mix</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Hourly</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Weekly</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Tender</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Channel</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>DSR</Tab>
                                                <Tab _selected={{ color: 'white', bg: 'blue.500' }}>NPS</Tab>
                                        </TabList>
                                        <TabPanels height={{
                                                base: '100%', // 0-48em
                                                md: '50%', // 48em-80em,
                                                xl: '25%', // 80em+
                                        }} width="100%" m={0} p={0}>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBPMIXMobile />
                                                                ) : (
                                                                        <TableauEmbedPBPMIX />
                                                                )
                                                                )}
                                                </TabPanel>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBHourlyMobile />
                                                                ) : (
                                                                        <TableauEmbedPBHourly />
                                                                )

                                                                )}
                                                </TabPanel>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBWeeklyMobile />
                                                                ) : (
                                                                        <TableauEmbedPBWeekly />
                                                                )

                                                                )}
                                                </TabPanel>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBTenderMobile />
                                                                ) : (
                                                                        <TableauEmbedPBTender />
                                                                )
                                                                )}
                                                </TabPanel>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBChannelMobile />
                                                                ) : (
                                                                        <TableauEmbedPBChannel />
                                                                )
                                                                )}
                                                </TabPanel>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBDSRMobile />
                                                                ) : (
                                                                        <TableauEmbedPBDSR />
                                                                )
                                                                )}
                                                </TabPanel>
                                                <TabPanel m={0} p={0}>
                                                        {firstLoad ? (<Spinner size={"xl"} mt={20} />) :
                                                                (isSmallerThan600 ? (
                                                                        <TableauEmbedPBNPSMobile />
                                                                ) : (
                                                                        <TableauEmbedPBNPS />
                                                                )
                                                                )}
                                                </TabPanel>
                                                
                                        </TabPanels>
                                </Tabs>
                        </div>

                </>
        );
};

export default Reports;