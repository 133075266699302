import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    Button,
    Box,
    Tag,
    TagLabel,
    TagCloseButton,
    useToast,
    Spinner
} from '@chakra-ui/react'
import axios from 'axios'


const brands = [
    "KR",
    "SBC",
    "JB",
    "PB"
]

const UpdateAccessModal = ({ modalOpen, modalClose, user }) => {
    const toast = useToast()
    const [access, setAcces] = useState([])
    const [availableBrands, setAvailable] = useState([...brands])
    const [trigger, setTrigger] = useState(false) // this state will be triggered so that the display will rerender and display the updated layout.
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (user.selectedUserAcces) {
            setAcces(user.selectedUserAcces)
        }
    }, [user.selectedUserAcces])
    const removeBrand = (brandToRemove) => {
        const updatedAcces = access.filter(brand => brand !== brandToRemove)
        setAcces(updatedAcces)
        setTrigger(prev => !prev)
    }
    const addBrand = (brandToAdd) => {
        const updatedAcces = [...access, brandToAdd]
        setAcces(updatedAcces)
    }
    const submitHandler = async () => {
        setIsSubmitting(true)
        if (access.length <= 0) {
            toast({
                title: 'Error',
                position: "top-right",
                description: "User Access must not be empty.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setIsSubmitting(false)
            return
        }
        if (user.selectedUserAcces === access) {
            toast({
                title: 'Error',
                position: "top-right",
                description: "You made no changes in this User Access.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setIsSubmitting(false)
            return
        }
        try {
            console.log(process.env.REACT_APP_BACKEND_API_URL)
            await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/updateUserAccess`, {
                id: user.selectedUserId,
                access: access
            }, { withCredentials: true })
            toast({
                title: 'Success',
                position: "top-right",
                description: "You successfully updated the user Access.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setIsSubmitting(false)
            modalClose()
        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setIsSubmitting(false)
        }
        setIsSubmitting(false)
    }
    return (
        <Modal blockScrollOnMount={false} isOpen={modalOpen} onClose={modalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Update Access</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Text as="b" mb='1rem' mr={3}>
                            ID:
                        </Text>
                        {user.selectedUserId}
                    </Box>
                    <Box>
                        <Text as="b" mb='1rem' mr={3}>
                            User:
                        </Text>
                        {user.selectedUser}
                    </Box>
                    <Box mt={5} mb={4}>
                        <Text as="b" mr={3}>
                            This User Access:
                        </Text>
                    </Box>
                    <Box mb={4}>
                        {access.length === 0 ? "This user has no access." : access.map((brand) => {
                            let colorScheme = "gray"
                            switch (brand) {
                                case 'KR':
                                    colorScheme = 'orange';
                                    break;
                                case 'PB':
                                    colorScheme = 'blue';
                                    break;
                                case 'JB':
                                    colorScheme = 'green';
                                    break;
                                case 'SBC':
                                    colorScheme = 'red';
                                    break;
                                default:
                                    colorScheme = 'gray';
                            }
                            return (
                                <Tag
                                    size={"lg"}
                                    key={brand}
                                    borderRadius='full'
                                    variant='solid'
                                    colorScheme={colorScheme}
                                    ml={2}
                                    mb={2}
                                    onClick={() => removeBrand(brand)}
                                >
                                    <TagLabel>{brand}</TagLabel>
                                    <TagCloseButton />
                                </Tag>
                            )
                        })}

                    </Box>
                    {access.length <= 3 &&
                        <Box>
                            <Text as={"b"} >
                                Add Brand:
                            </Text>
                            <Box mt={4}>
                                {availableBrands
                                    .filter((brand) => !access.includes(brand)) // Filter out brands already in user's access
                                    .map((brand) => {
                                        let colorScheme = "gray";
                                        switch (brand) {
                                            case 'KR':
                                                colorScheme = 'orange';
                                                break;
                                            case 'PB':
                                                colorScheme = 'blue';
                                                break;
                                            case 'JB':
                                                colorScheme = 'green';
                                                break;
                                            case 'SBC':
                                                colorScheme = 'red';
                                                break;
                                            default:
                                                colorScheme = 'gray';
                                        }
                                        return (
                                            <Tag
                                                size={"lg"}
                                                key={brand}
                                                borderRadius='full'
                                                variant='solid'
                                                colorScheme={colorScheme}
                                                ml={2}
                                                onClick={() => addBrand(brand)}
                                                cursor={"pointer"}
                                                mb={2}
                                            >
                                                <TagLabel>{brand}</TagLabel>
                                            </Tag>
                                        );
                                    })}
                            </Box>
                        </Box>
                    }
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' onClick={submitHandler} isDisabled={isSubmitting}>{isSubmitting ? <Spinner /> : "Submit"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default UpdateAccessModal