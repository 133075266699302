import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    FormControl,
    Input,
    useToast,
    Spinner,
} from '@chakra-ui/react'
import axios from 'axios'
import DataTable from 'react-data-table-component'

const ResetPasswordModal = ({ modalOpen, modalClose }) => {
    const toast = useToast()
    const [userNameOrId, setUserNameOrId] = useState()
    const [checkUser, setCheckUser] = useState(false)
    const [foundUser, setFoundUser] = useState("")
    const [Password, setPassword] = useState({
        newPass: "",
        cNewPass: ""
    })
    const [submitValid, setSubmitValid] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    useEffect(() => {
        if (!modalOpen) {
            setUserNameOrId("")
            setFoundUser("")
            setSubmitValid(false)
            setPassword({
                newPass: "",
                cNewPass: ""
            })
        }
    }, [modalOpen])
    const validateHandler = async () => {
        setCheckUser(true)
        if (!userNameOrId) {
            toast({
                title: 'Error',
                position: "top-right",
                description: "UserName or ID must be provided.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            return setCheckUser(false)
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/validateUser`, {
                userNameOrId: userNameOrId
            })
            setFoundUser([res.data.user])
            toast({
                title: 'Success',
                position: "top-right",
                description: "User found.",
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            setSubmitValid(true)
            setCheckUser(false)
        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setCheckUser(false)
        }

    }
    const handleSubmit = async () => {
        setSubmitting(true)
        let password
        if (Password.cNewPass !== Password.newPass) {
            toast({
                title: 'Error',
                position: "top-right",
                description: "New Password and Confirm Password does not match.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setSubmitting(false)
            return
        }
        if (!Password.cNewPass && !Password.newPass) {
            password = "123456"
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/userChangePassword`, {
                id: foundUser[0].idlogin,
                password: Password.newPass ? Password.newPass : password,
                cPassword: Password.cNewPass ? Password.cNewPass : password,
            }, { withCredentials: true })
            setSubmitting(false)
            toast({
                title: 'Success',
                position: "top-right",
                description: res.data.message,
                status: 'success',
                duration: 9000,
                isClosable: true,
            })
            modalClose()

        } catch (err) {
            toast({
                title: 'Error',
                position: "top-right",
                description: err.response.data.message,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
            setSubmitting(false)
        }

    }
    const handleInputChange = (e) => {
        const { name, value } = e.target
        setPassword((prev) => (
            {
                ...prev,
                [name]: value
            }
        ))
    }


    return (
        <Modal isOpen={modalOpen} onClose={modalClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Text color="blue" style={{ display: 'inline' }} as={"b"}>Reset </Text>
                    <Text style={{ display: 'inline' }} as={"b"}>Password</Text>

                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl>
                        <Text as={"b"}>ID or Username of the user you want to </Text>
                        <Text color="blue" style={{ display: 'inline' }} as={"b"}>Reset </Text>
                        <Text style={{ display: 'inline' }} as={"b"}>the Password.</Text>
                        <Input mt={2} placeholder='ID or Username' value={userNameOrId} onChange={(e) => setUserNameOrId(e.target.value)} name="userNameOrId" />
                    </FormControl>
                    {foundUser && <><DataTable
                        columns={[
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>ID</div>
                                ),
                                selector: (row) => row.idlogin,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Username</div>
                                ),
                                selector: (row) => row.username,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>First name</div>
                                ),
                                selector: (row) => row.firstname,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Last name</div>
                                ),
                                selector: (row) => row.lastname,
                                sortable: true,
                                filterable: true,
                            },

                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Department</div>
                                ),
                                selector: (row) => row.department,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Role</div>
                                ),
                                selector: (row) => row.role,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Ownership</div>
                                ),
                                selector: (row) => row.ownership,
                                sortable: true,
                                filterable: true,
                            },
                            {
                                name: (
                                    <div style={{ fontSize: 15, fontWeight: "bold" }}>Access</div>
                                ),
                                selector: (row) => row.access.join(', '),
                                sortable: true,
                                filterable: true,
                            },
                        ]}
                        data={foundUser}
                        searchable
                        //  customFilterText={customFilterText}
                        highlightOnHover
                    />
                        <FormControl mt={8}>
                            <Text as={"b"}>Enter New Password</Text>
                            <Input mt={2} type='password' placeholder='New Password' value={Password.newPass} onChange={handleInputChange} name="newPass" />
                        </FormControl>
                        <FormControl mt={4}>
                            <Text as={"b"}>ConfirmPass</Text>
                            <Input mt={2} type='password' placeholder='Re-Enter New Password' value={Password.cNewPass} onChange={handleInputChange} name="cNewPass" />
                        </FormControl>
                        <Text fontSize='xs' style={{ color: 'gray', fontSize: 12 }} >If the you entered nothing. The default password will be '123456'.</Text>
                    </>
                    }
                </ModalBody>

                <ModalFooter display={"flex"} justifyContent={"space-between"}>
                    <Button colorScheme='green' mr={3} isDisabled={checkUser} onClick={validateHandler}>
                        {checkUser ? <Spinner /> : "Validate"}
                    </Button>
                    <Button colorScheme='blue' mr={3} isDisabled={!submitValid || submitting} onClick={handleSubmit}>
                        {submitting ? <Spinner /> : "Submit"}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default ResetPasswordModal