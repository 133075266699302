import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Box,
    Input,
    useToast
} from '@chakra-ui/react'
import axios from "axios"

const ConfirmModal = ({ modal, mode, data, changeMode, trigger }) => {
    const [inputData, setInputData] = useState({
        storeName: data.StoreName,
        locCode: data.locCode,
        storeCode: data.storecode
    })
    const toast = useToast()
    const handleClose = () => {
        changeMode()
        modal.toggle()
    }
    const handleInputChange = (e) => {
        setInputData({ ...inputData, [e.target.name]: e.target.value })
    }
    let content;
    if (mode === "delete") {
        content = <Box display={"flex"} flexDirection={"column"}>
            <Text as={"b"}>Store Name: {data.StoreName}</Text>
            <Text as={"b"}>Loc Code: {data.locCode}</Text>
            <Text as={"b"}>Store Code: {data.locCode}</Text>
        </Box>
    } else {
        content = <Box display={"flex"} flexDirection={"column"}>
            <Box mb={4}>
                <Text>Store Name: </Text>
                <Input name='storeName' defaultValue={data.StoreName} value={inputData.storeName} onChange={(e) => handleInputChange(e)} />
            </Box>
            <Box>
                <Text>Loc Code: </Text>
                <Input name='locCode' defaultValue={data.locCode} value={inputData.locCode} onChange={(e) => handleInputChange(e)} />
            </Box>
            <Box>
                <Text>Store Code: </Text>
                <Input name='storeCode' defaultValue={data.storeCode} value={inputData.storeCode} onChange={(e) => handleInputChange(e)} />
            </Box>
        </Box>
    }
    const handleAction = async () => {
        if (mode === "delete") {
            try {
                const resDelete = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/maintenance/deleteStore`, {
                    storeId: data.StoreID
                })
                toast({
                    title: "Success",
                    description: resDelete.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            } catch (e) {
                return toast({
                    title: "Error",
                    description: e.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }

        } else {
            try {
                const resUpdate = await axios.post(`${process.env.REACT_APP_BACKEND_API_URL}/maintenance/updateStore`, {
                    storeId: data.StoreID,
                    storeName: inputData.storeName,
                    locCode: inputData.locCode,
                    storeCode: inputData.storeCode
                })
                toast({
                    title: "Success",
                    description: resUpdate.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                })
            } catch (e) {
                return toast({
                    title: "Error",
                    description: e.message,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
        }
        changeMode()
        trigger()
        modal.toggle()
    }
    return (
        <Modal isOpen={modal.isOpen} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{mode === "delete" ? "Confirm Remove" : "Update Store"}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {mode === "delete" ? <Text mb={4}>Are you sure you want to remove this store?</Text> : <Box>
                        <Text mb={4}>Update store: </Text>
                        {/* <Text>{data.}</Text> */}
                    </Box>}
                    <Text as={"b"} color={data.color} mb={4}>Brand: {data.brandName}</Text>
                    {content}
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' mr={3} onClick={handleClose}>
                        Close
                    </Button>
                    <Button colorScheme={mode === "delete" ? 'red' : "blue"} onClick={handleAction}>{mode === "delete" ? "Delete" : "Update"}</Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ConfirmModal