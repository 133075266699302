import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import KR from './Access/KR/KR';
import JB from './Access/JB/JB';
import PB from './Access/PB/PB';
import SBC from './Access/SBC/SBC';
import UserManagement from "./UserManagement/UserManagement";
import BrandsManagement from "./BrandManagement/BrandsManagement";
import DeactivatedUserPage from "./DeactivatedUserPage";
import Page404 from './Page404';
import { jwtDecode } from 'jwt-decode';

import Dashboard from './Dashboard';


function ProtectedRoute() {
    const token = Cookies.get('accessToken');
    const decodedToken = token ? jwtDecode(token) : null;
    const userRole = decodedToken ? decodedToken.role : null;
    const userAccess = decodedToken ? decodedToken.access : null;
    const userId = decodedToken ? decodedToken.idlogin : null
    if (userRole && userRole === "deactivated") {
        return <DeactivatedUserPage />;
    }
    if (!token) {
        // Redirect to the home page if the user is not logged in
        return <Navigate to="/" />;
    }
    return (
        <Routes>
            <Route path="homepage" element={token ? <Dashboard /> : <Navigate to="/" />} />
            {userAccess && userAccess.includes('KR') && (
                <Route path="kr" element={token && <KR />} />
            )}
            {userAccess && userAccess.includes('SBC') && (
                <Route path="sbc" element={token && <SBC />} />
            )}
            {userAccess && userAccess.includes('JB') && (
                <Route path="jb" element={token && <JB />} />
            )}
            {userAccess && userAccess.includes('PB') && (
                <Route path="pb" element={token && <PB />} />
            )}
            {userRole && userRole === "admin" && (
                <Route path="userManagement" element={userRole === "admin" && <UserManagement currentUserId={userId} />} />
            )}
            {userRole && userRole === "admin" && (
                <Route path="brandManagement" element={userRole === "admin" && <BrandsManagement currentUserId={userId} />} />
            )}
            <Route path='*' element={<Page404 />} />
        </Routes>
    );
}

export default ProtectedRoute;
