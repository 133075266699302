import React, { useEffect, useState, useRef } from 'react';
import { Box, Spinner, useToast, useMediaQuery } from '@chakra-ui/react';

function TableauEmbedPBNPS({ url }) {
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(true);
    const iframeRef = useRef(null);
    const [isSmallerThan600] = useMediaQuery("(max-width: 600px)");

    useEffect(() => {
        const iframe = iframeRef.current;
        const handleLoad = () => {
            console.log("Iframe loaded");
            setIsLoading(false);
        };

        const handleError = () => {
            console.log("Iframe error");
            setIsLoading(false);
            toast({
                position: "top",
                title: 'Error!',
                description: "Error loading Tableau. Please click refresh.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        };

        if (iframe) {
            iframe.addEventListener('load', handleLoad);
            iframe.addEventListener('error', handleError);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handleLoad);
                iframe.removeEventListener('error', handleError);
            }
        };
    }, [toast]);

    return (
        <Box
            width="100%"
            height={isSmallerThan600 ? 2000 : "73vh"}
            boxShadow="0px 4px 8px 0px rgba(0, 0, 0, 0.2)"
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="relative"
        >
            {isLoading && (
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                >
                    <Spinner size="xl" />
                </Box>
            )}
            <iframe
                ref={iframeRef}
                src="https://reports.ftsfood.com.ph/tableau/pbnps.html"
                style={{ display: isLoading ? 'none' : 'block', width: '100%', height: '100%' }}
                title="Tableau Report"
                scrolling='no'
            />
        </Box>
    );
}

export default TableauEmbedPBNPS;