import React, { useState } from 'react';
//import JB_Sidebar from '../../Access_Sidebar/JB_SideBar';
import { Flex, Box } from '@chakra-ui/react';
import jb_cover from '../../../Assets/jb_cover.png';
// import KR_NavBar from '../../../Access_NavBar/KR_NavBar';
import jb_logo from '../../../Assets/jb_logo.png';
import NavBar from '../../../Components/Navbar/NavBar';
import Sales from "./SalesJB"
import Reports from "./ReportsJB"
import About from './AboutJB';
import DPicker from '../../../Components/DPicker';
function JB() {
    const [selectedOption, setSelectedOption] = useState('Sales');
    const [dataInPDF, setDataInPDF] = useState()

    const handleTabClick = (tab) => {
        setSelectedOption(tab);
    };

    return (
        <Box>
            {/* <KR_NavBar onTabClick={handleTabClick} logo={jb_logo} tabIndicatorColor="green.500" /> */}
            <NavBar tabIndicatorColor="green.500" logo={jb_logo} onTabClick={handleTabClick} />
            <Flex justifyContent="center" position="relative">

                <Box
                    mb={40}
                    style={{
                        backgroundImage: `url(${jb_cover})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: "100%",
                        height: '250px'
                    }}
                />
            </Flex>


            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} w={"100%"} h="calc(100vh - 400px)">
                <Box mt={20} w={"80%"}>
                    {/* Render content based on the selected option */}
                    {selectedOption === 'About' && <About content={"About JB"} color={"green"} />}
                    {selectedOption === 'Sales' && <Sales content={"Sales JB"} color={"green"} />}
                    {selectedOption === 'Reports' && <Reports content={"Reports JB"} color={'green'} />}
                </Box>
            </Box>
        </Box>
    );
}

export default JB;
