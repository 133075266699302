import './App.css';
import Login from './pages/Login/Login';
import Register from './pages/Register';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Cookies from "js-cookie";

import ProtectedRoute from "./pages/ProtectedRoute";

function App() {

  const isAuthenticated = Cookies.get("accessToken");
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={isAuthenticated ? <Navigate to="/homepage" /> : <Login />} />
          <Route path="/*" element={<ProtectedRoute />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
