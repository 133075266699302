import CryptoJS from "crypto-js"

export function generateSHAHeader(startDate, endDate) {
    const req_body = {
        "EODSalesOnly": false,
        "IncludeItemDetails": false,
        "RowVersion": "12345678",
        "filter": {
            "logic": "and",
            "filters": [
                {
                    "field": "BizDate",
                    "operator": "gte",
                    "value": `${startDate}`
                },
                {
                    "field": "BizDate",
                    "operator": "lte",
                    "value": `${endDate ? endDate : startDate}`
                }
            ]
        }
    };
    const jsonString = JSON.stringify(req_body, null, 0)

    const concatenatedString = process.env.REACT_APP_ZEONIQ_APIKEY + jsonString
    const sha256Header = CryptoJS.SHA256(concatenatedString).toString()
    return sha256Header
}

export function generateHeaders(SHAHeader) {
    return {
        "Content-Type": "application/json;charset=UTF-8",
        "SubscriptionId": "D00047",
        "ModCode": "OPENF",
        "Signature": SHAHeader
    }
}

export function generateReqBody(startdate, endDate) {
    return {
        "EODSalesOnly": false,
        "IncludeItemDetails": false,
        "RowVersion": "12345678",
        "filter": {
            "logic": "and",
            "filters": [
                {
                    "field": "BizDate",
                    "operator": "gte",
                    "value": startdate
                },
                {
                    "field": "BizDate",
                    "operator": "lte",
                    "value": endDate ? endDate : startdate
                }
            ]
        }
    };
}

export function generateRandomString() {
    return Math.random().toString(36).substring(2, 8);
}