import React, { useState, useEffect } from 'react';
import { Grid, Flex, Box } from "@chakra-ui/react";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from "react-router-dom";
import kr_img from '../Assets/kr_logo.png';
import sbc_img from '../Assets/sbc_logo.png';
import jb_img from '../Assets/jb_logo.png';
import pb_img from '../Assets/pb_logo.png';
import NB from '../Components/Navbar/NavBar';

import kr_cover from '../Assets/kr_cover.png';
import sbc_cover from '../Assets/sbc_cover.png';
import jb_cover from '../Assets/jb_cover.png';
import pb_cover from '../Assets/pb_cover.png';
import { jwtDecode } from 'jwt-decode';

export default function Dashboard() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [kr_cover, sbc_cover, jb_cover, pb_cover];
    const [userRole, setUserRole] = useState([]);


    useEffect(() => {
        // FUNCTION FOR DECODING THE TOKEN RESPONSE FROM THE BACKEND
        const decodeToken = () => {
            const token = document.cookie.replace(/(?:(?:^|.*;\s*)accessToken\s*=\s*([^;]*).*$)|^.*$/, "$1");
            if (token) {
                const decodedToken = jwtDecode(token);
                return decodedToken;
            }
            return null;
        };

        const decodedToken = decodeToken();
        if (decodedToken) {
            const role = decodedToken.role;
            const access = decodedToken.access;
            setUserRole(role === "admin" ? ['KR', 'SBC', 'PB', 'JB'] : access);
        }
    }, []);


    return (
        <div style={{ backgroundColor: 'white', }}>
            <NB />
            <Flex
                justifyContent="center"
                alignItems="center"
                width="100%"
            >
                <div style={{ width: "100%" }}>
                    <Carousel
                        border="1px solid red"
                        autoPlay={true}
                        infiniteLoop={true}
                        interval={3000}
                        showThumbs={false}
                        showStatus={false}
                        onChange={(index) => setCurrentIndex(index)}
                        selectedItem={currentIndex}
                    >
                        {images.map((image, index) => (
                            <div key={index}>
                                <img src={image} alt={`Slide ${index}`} />
                            </div>
                        ))}
                    </Carousel>
                </div>
            </Flex>

            <Flex justifyContent="center" alignItems="center" mt="6px">

                <Grid
                    justifyItems="center"
                    alignItems="center"
                    marginLeft="25px"
                    p="20px"
                    gap={15}
                    templateColumns={{ base: "1fr" }}
                    maxWidth="1200px"
                >
                    <Flex justifyContent="center" display={"flex"} flexDirection={{ base: "column", lg: "row" }} alignItems="center" mt="10px">

                        {userRole.includes('KR') && (
                            <Box
                                width="250px"
                                height="250px"
                                bg="gray.300"
                                borderRadius="50%"
                                textAlign="center"
                                p="20px"
                                margin="10px"
                                transition="transform 0.3s ease-in-out"
                                _hover={{
                                    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
                                    transform: "scale(1.1)"
                                }}
                            >
                                <Link to="/kr">
                                    <img src={kr_img} alt="KR Logo" style={{ width: '100%', height: 'auto', borderRadius: '50%' }} />
                                </Link>
                            </Box>
                        )}

                        {userRole.includes("SBC") && (
                            <Box
                                width="250px"
                                height="250px"
                                bg="gray.300"
                                borderRadius="50%"
                                textAlign="center"
                                p="20px"
                                margin="10px"
                                transition="transform 0.3s ease-in-out"
                                _hover={{
                                    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
                                    transform: "scale(1.1)"
                                }}
                            >
                                <Link to="/sbc">
                                    <img src={sbc_img} alt="SB Logo" style={{ width: '100%', height: 'auto', borderRadius: '50%' }} />
                                </Link>
                            </Box>
                        )}

                        {userRole.includes("JB") && (
                            <Box
                                width="250px"
                                height="250px"
                                bg="gray.300"
                                borderRadius="50%"
                                textAlign="center"
                                p="20px"
                                margin="10px"
                                transition="transform 0.3s ease-in-out"
                                _hover={{
                                    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
                                    transform: "scale(1.1)"
                                }}
                            >
                                <Link to="/jb">
                                    <img src={jb_img} alt="JB Logo" style={{ width: '100%', height: 'auto', borderRadius: '50%' }} />
                                </Link>
                            </Box>
                        )}

                        {userRole.includes("PB") && (
                            <Box
                                width="250px"
                                height="250px"
                                bg="gray.300"
                                borderRadius="50%"
                                textAlign="center"
                                p="20px"
                                margin="10px"
                                transition="transform 0.3s ease-in-out"
                                _hover={{
                                    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.3)",
                                    transform: "scale(1.1)"
                                }}
                            >
                                <Link to="/pb">
                                    <img src={pb_img} alt="PB Logo" style={{ width: '100%', height: 'auto', borderRadius: '50%' }} />
                                </Link>
                            </Box>
                        )}

                    </Flex>
                </Grid>

            </Flex>
        </div>
    )
}