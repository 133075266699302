import React from 'react'
import { Box, Image, Text } from '@chakra-ui/react'
import NavBar from '../Components/Navbar/NavBar'


const Page404 = () => {
    return (
        <>
            <NavBar in404 />
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} width={"100%"} height={"calc(100vh - 100px)"}>
                <Box mr={6}>
                    <Image boxSize='200px' src="/404-error.svg" alt='Dan Abramov' />
                </Box>
                <Box>
                    <Text as={"b"}>The page youre looking is not found.</Text>
                </Box>
            </Box>

        </>
    )
}

export default Page404