import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  FormErrorMessage,
  Link,
  Select,
  Checkbox,
  Text
} from "@chakra-ui/react";
import { FaUserAlt, FaLock, FaEnvelope, FaBuilding } from "react-icons/fa";
import axios from "axios";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);
const CFaEnvelope = chakra(FaEnvelope);
const CFaBuilding = chakra(FaBuilding);

const Register = ({ onFormSwitch }) => {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfirmPassword] = useState('');
  const [department, setDepartment] = useState('');
  const [access, setAccess] = useState([]);
  const [role, setRole] = useState('');
  const [ownership, setOwnership] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();



  const handlePasswordFocus = () => {
    setShowPassword(true);
  };

  const handlePasswordBlur = () => {
    setShowPassword(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setShowPassword(false);
  };

  const handleRegisterFunction = async (e) => {
    e.preventDefault();
    if (password !== confPassword) {
      setPasswordsMatch(false);
      return;
    }

    let accessToSend = access;
    if (access.includes('ALL')) {
      // If 'ALL' is included, replace it with other options
      accessToSend = ['KR', 'SBC', 'JB', 'PB'];
    }

    try {
      console.log("Form data:", {
        firstname,
        lastname,
        email,
        password,
        confPassword,
        department,
        access: accessToSend, role, ownership
      });

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_API_UR}/users`, {
        firstname: firstname,
        lastname: lastname,
        email: email,
        password: password,
        confPassword: confPassword,
        department: department,
        access: accessToSend,
        role: role,
        ownership: ownership
      });

      console.log("Response:", response);

      console.log("Response Data:", response.data);

      if (response.data.msg === "Registration Successful") {
        // Save user information to local storage upon successful registration
        const userInfo = {
          firstname: firstname,
          lastname: lastname,
          email: email,
          department: department,
          roles: role,
          ownership: ownership,
          access: access
        };
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        alert("Registration Successful");
        navigate("/");
      } else {
        alert("Registration Failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Registration Failed");
    }
  };
  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="blue.500" />
        <Heading color="blue.400">Register</Heading>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={handleRegisterFunction}>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              borderWidth="1px"
              borderColor="gray.400"
              borderRadius="md"
            >
              <Flex justifyContent="space-between" >
                <FormControl flex="1" mr="0.5rem" isInvalid={!passwordsMatch}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="First Name"
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      _placeholder={{ color: 'gray.500' }}
                      color="black"
                      borderColor="gray.300"
                      borderWidth="1px"
                      required
                    />
                  </InputGroup>
                </FormControl>

                <FormControl flex="1" ml="0.5rem" isInvalid={!passwordsMatch}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaUserAlt color="gray.300" />}
                    />
                    <Input
                      type="text"
                      placeholder="Last Name"
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      _placeholder={{ color: 'gray.500' }}
                      color="black"
                      borderColor="gray.300"
                      borderWidth="1px"
                      required
                    />
                  </InputGroup>
                </FormControl>
              </Flex>

              <FormControl flex="1" mr="0.5rem" isInvalid={!passwordsMatch}>
                <Flex>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<CFaEnvelope color="gray.300" />}
                    />
                    <Input
                      type="email"
                      placeholder="Email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      _placeholder={{ color: 'gray.500' }}
                      color="black"
                      borderColor="gray.300"
                      borderWidth="1px"
                      required
                    />
                  </InputGroup>
                </Flex>
              </FormControl>

              <FormControl flex="1" mr="0.5rem" isInvalid={!passwordsMatch}>
                <Flex>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                      onFocus={handlePasswordFocus}
                      onBlur={handlePasswordBlur}
                      _placeholder={{ color: 'gray.500' }}
                      color="black"
                      borderColor="gray.300"
                      borderWidth="1px"
                      required
                    />
                  </InputGroup>
                  <InputGroup ml="0.5rem">
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      children={<CFaLock color="gray.300" />}
                    />
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder="Confirm Password"
                      value={confPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      _placeholder={{ color: 'gray.500' }}
                      color="black"
                      borderColor="gray.300"
                      borderWidth="1px"
                      required
                    />
                  </InputGroup>
                </Flex>
                <FormErrorMessage>Passwords do not match</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!passwordsMatch}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaBuilding color="gray.300" />}
                  />
                  <Input
                    type="text"
                    placeholder="Department"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    _placeholder={{ color: 'gray.500' }}
                    color="black"
                    borderColor="gray.300"
                    borderWidth="1px"
                    required
                  />
                </InputGroup>
              </FormControl>

              <FormControl isInvalid={!passwordsMatch}>
                <Select
                  placeholder="Select Role"
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                    if (e.target.value === 'admin') {
                      setAccess(['ALL']);
                    } else {
                      setAccess([]);
                    }
                  }}
                  _placeholder={{ color: 'gray.500' }}
                  color="black"
                  borderColor="gray.300"
                  borderWidth="1px"
                  required
                >
                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="manager">Manager</option>
                </Select>
              </FormControl>

              <FormControl isInvalid={!passwordsMatch}>
                <Select
                  placeholder="Select Ownership"
                  value={ownership}
                  onChange={(e) => setOwnership(e.target.value)}
                  _placeholder={{ color: 'gray.500' }}
                  color="black"
                  borderColor="gray.300"
                  borderWidth="1px"
                  required
                >
                  <option value="company-owned">Company Owned</option>
                  <option value="franchise">Franchise</option>
                  <option value="none">None</option>
                </Select>
              </FormControl>

              <FormControl isInvalid={!passwordsMatch}>
                <Flex align="center">
                  <Text mr="1rem">Access:</Text>
                  <Stack direction="row" spacing={2}>
                    {access.includes('ALL') ? (
                      <Checkbox
                        isChecked={true}
                        onChange={() => {
                          setAccess([]);
                        }}

                      >
                        ALL
                      </Checkbox>
                    ) : (
                      <>
                        <Checkbox
                          isChecked={access.includes('KR')}
                          onChange={() => setAccess(access => access.includes('KR') ? access.filter(item => item !== 'KR') : [...access, 'KR'])}

                        >
                          Kenny Rogers
                        </Checkbox>

                        <Checkbox
                          isChecked={access.includes('SBC')}
                          onChange={() => setAccess(access => access.includes('SBC') ? access.filter(item => item !== 'SBC') : [...access, 'SBC'])}

                        >
                          Seattle's Best
                        </Checkbox>

                        <Checkbox
                          isChecked={access.includes('JB')}
                          onChange={() => setAccess(access => access.includes('JB') ? access.filter(item => item !== 'JB') : [...access, 'JB'])}

                        >
                          Joybean
                        </Checkbox>

                        <Checkbox
                          isChecked={access.includes('PB')}
                          onChange={() => setAccess(access => access.includes('PB') ? access.filter(item => item !== 'PB') : [...access, 'PB'])}

                        >
                          Paris Baguette
                        </Checkbox>
                      </>
                    )}
                  </Stack>
                </Flex>
              </FormControl>

              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="blue"
                width="full"
              >
                Register
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
      <Box color="black">
        Already have an account?{" "}
        <Link as={RouterLink} to="/" color="blue.500">
          Sign In
        </Link>
      </Box>

    </Flex>
  );
};

export default Register;
